import { React, useState, useEffect } from 'react'
import HeaderTemp from '../Header/HeaderTemp'
import FooterNew from '../Footer/FooterNew'
import AOS from 'aos'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { makeStyles } from '@material-ui/styles'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Banner from '../BannerCarousel/Banner'
import Alert from 'react-bootstrap/Alert'
const axios = require('axios')

const useStyles = makeStyles((theme) => ({
  map: {
    width: '100%',
    height: '445px',
    margin: '2rem',
    [theme.breakpoints.up('mobileS')]: {
      margin: '1rem 0',
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('smallLaptop')]: {
      margin: '1rem 0',
      width: '100%',
      height: '445px',
    },
  },
  info: {
    margin: '2rem',
    padding: '2rem',
    [theme.breakpoints.up('mobileS')]: {
      margin: '0',
      padding: '10px',
      width: '100%',
      height: '100%',
    },
    '& h1': {
      fontFamily: theme.fontFamily.heading,
    },
    '& p': {
      fontFamily: theme.fontFamily.text,
    },
    borderRadius: '20px',
    // boxShadow:
    //   'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
  },
  form: {
    margin: '2rem',
    padding: '2rem',
    [theme.breakpoints.up('mobileS')]: {
      margin: '0',
      padding: '10px',
    },
    fontFamily: theme.fontFamily.text,
    borderRadius: '20px',
    // boxShadow:
    //   'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
  },
}))

function ContactUsPage() {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')
  const [number, setNumber] = useState('')
  const [show, setShow] = useState(false)
  const [error, setErrorShow] = useState(false)

  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
  }, [])

  const emailHandler = (e) => {
    setEmail(e.target.value)
  }

  const messageHandler = (e) => {
    setMessage(e.target.value)
  }

  const firstHandler = (e) => {
    setFirst(e.target.value)
  }

  const secondHandler = (e) => {
    setLast(e.target.value)
  }

  const numberHandler = (e) => {
    setNumber(e.target.value)
  }

  const onSubmit = () => {
    console.log(first),
      console.log(last),
      console.log(number),
      console.log(message),
      console.log(email)
    axios
      .post('https://cogenthub-api.herokuapp.com/contact/createQuery', {
        firstName: first,
        lastName: last,
        email: email,
        message: message,
        number: number,
      })
      .then(function (response) {
        setShow(true)
        window.location.href = '/contact-us/thank-you'
        console.log('Storage', response)
      })
      .catch((error) => {
        setErrorShow(true)
        console.log('Storage', error.response)
      })
  }

  return (
    <>
      <div
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-sine"
      >
        <HeaderTemp />
      </div>
      <Banner title="Contact Us" subTitle="" />
      <Container>
        <Row>
          <Col md={true}>
            <div>
              <iframe
                className={classes.map}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.9649530614884!2d88.43495321495969!3d22.58041408517872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275a94dd40001%3A0x9d0f74c71ae0ad26!2sCogentHub%20Private%20Limited!5e0!3m2!1sen!2sin!4v1616161540347!5m2!1sen!2sin"
              ></iframe>
            </div>
          </Col>
          <Col md={true}>
            <div className={classes.info}>
              <h1>Address</h1>
              <p style={{ fontSize: '16px' }}>
                CogentHub Pvt Ltd Martin Burn Business Park, Level 7, Plot 3, BP
                Block, Sector V, Bidhannagar, Kolkata 700091, West Bengal India
              </p>
              <h1>Mail Us</h1>
              <p>info@cogenthub.com</p>
              <h1>Terms & Conditions</h1>
              <p>Privacy Policy</p>
              <p>Terms </p>
            </div>
          </Col>
        </Row>
        {show && (
          <Alert variant="success" onClose={() => setShow(false)} dismissible>
            <Alert.Heading>We have received your message!</Alert.Heading>
            <p>
              Thank you for reaching out to us! We will get back to your
              shortly.
            </p>
          </Alert>
        )}
        {error && (
          <Alert
            variant="danger"
            onClose={() => setErrorShow(false)}
            dismissible
          >
            <Alert.Heading>There has been an Error!</Alert.Heading>
            <p>Please Make Sure All Fields are Filled In Properly!</p>
          </Alert>
        )}
        <Form className={classes.form}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '19px', fontWeight: 'bold' }}>
                  How Can We Help?
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Message"
                  onChange={messageHandler}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '19px', fontWeight: 'bold' }}>
                  First Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  onChange={firstHandler}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '19px', fontWeight: 'bold' }}>
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  onChange={secondHandler}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '19px', fontWeight: 'bold' }}>
                  Email address
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={emailHandler}
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '19px', fontWeight: 'bold' }}>
                  Phone Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Phone Number"
                  onChange={numberHandler}
                />
                <Form.Text className="text-muted">
                  We'll never share your number with anyone else.
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <Button variant="outline-warning" onClick={onSubmit}>
                Submit
              </Button>{' '}
            </Col>
          </Row>
        </Form>
      </Container>
      <FooterNew />
    </>
  )
}

export default ContactUsPage

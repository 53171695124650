import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Carousel from 'react-bootstrap/Carousel'
import content from '../../ContentJson/content.json'
import { makeStyles } from '@material-ui/styles'
import 'bootstrap/dist/css/bootstrap.css'
import Banner from './bannerImage.png'
import useWindowDimensions from '../../useWindowDimensions'

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    zIndex: '-100',
    height: '700px',
  },
  phoneConatiner: {
    overflow: 'hidden',
    position: 'relative',
    zIndex: '-200',
    height: '700px',
  },
  carousel: {
    position: 'relative',
    height: '700px',
  },
  phoneCarousel: {
    position: 'relative',
    height: '700px',
  },
  carouselItem: {
    height: '700px',
    '& img': {
      [theme.breakpoints.down('md')]: {
        height: '100vh',
      },
    },
  },
  phoneCarouselItem: {
    height: '700px',
  },
  carouselSlide: {
    position: 'absolute',
    width: '100%',
    top: '0',
    left: '0',
    minHeight: '300px',
    zIndex: -3,
  },
  carouselCaption: {
    position: 'relative',
    // backgroundColor: 'rgba(194, 183, 189, 0.7)',
    zIndex: '3',
    '& h1': {
      fontSize: theme.fontSize.bannerTitle,
      fontFamily: theme.fontFamily.heading,
      textShadow:
        '1px 1px 0px  #000, -1px -1px 0px  #000, 1px -1px 0px  #000, -2px 2px 0px  #000',
    },
    '& p': {
      fontSize: theme.fontSize.p,
      fontFamily: theme.fontFamily.text,
      textShadow:
        '0.5px 0.5px 0px  #000, -0.5px -0.5px 0px  #000, 0.5px -0.5px 0px  #000, -2px 2px 0px  #000',
    },
  },
}))

export default function ServiceBanner({ title, subTitle }) {
  const classes = useStyles()
  const { width, height } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 1024) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  }, [width])

  return (
    <>
      {!phoneView && (
        <div style={{ display: 'block' }} className={classes.container}>
          <Carousel className={classes.carousel} controls={false}>
            <Carousel.Item className={classes.carouselItem} interval={2000}>
              <img
                className={classes.carouselSlide}
                src={Banner}
                alt="First slide"
              />
              <Carousel.Caption>
                <div className={classes.carouselCaption}>
                  <h1>{title}</h1>
                  <p>{subTitle}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      )}
      {phoneView && (
        <div className={classes.phoneConatiner}>
          <Carousel controls={false} className={classes.phoneCarousel}>
            <Carousel.Item
              interval={2000}
              className={classes.phoneCarouselItem}
            >
              {width <= 320 && (
                <img
                  className={classes.carouselSlide}
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Banner320.png"
                  alt="First slide"
                />
              )}
              {width > 320 && width < 360 && (
                <img
                  className={classes.carouselSlide}
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Banner360.png"
                  alt="First slide"
                />
              )}
              {width > 360 && width <= 375 && (
                <img
                  className={classes.carouselSlide}
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Banner375.png"
                  alt="First slide"
                />
              )}
              {width > 375 && width <= 400 && (
                <img
                  className={classes.carouselSlide}
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Banner400.png"
                  alt="First slide"
                />
              )}
              {width > 400 && width <= 420 && (
                <img
                  className={classes.carouselSlide}
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Banner420.png"
                  alt="First slide"
                />
              )}
              {width > 420 && (
                <img
                  className={classes.carouselSlide}
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Banner320.png"
                  alt="First slide"
                />
              )}
              <Carousel.Caption>
                <div>
                  <h1>{title}</h1>
                  <p>{subTitle}</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      )}
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/styles'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useWindowDimensions from '../../useWindowDimensions'
import FooterImage from './footer-image.png'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${FooterImage})`,
    [theme.breakpoints.up('mobileS')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/phone-footer-webp.webp)`,
    },
    [theme.breakpoints.up('mobileM')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/375W.png)`,
    },
    [theme.breakpoints.up('mobile')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/425W.png)`,
    },
    [theme.breakpoints.up('smallLaptop')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/1024W.png)`,
    },
    [theme.breakpoints.up('middleLaptop')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/1366W.png)`,
    },
    [theme.breakpoints.up('macbook')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/1440W.png)`,
    },
    [theme.breakpoints.up('laptop')]: {
      backgroundImage: `url(${FooterImage})`,
    },
    [theme.breakpoints.up('desktop')]: {
      backgroundImage: `url(${FooterImage})`,
    },
    [theme.breakpoints.up('fourK')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/2560+W.png)`,
    },
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    // height: '25vh',
    width: '100%',
    fontFamily: theme.fontFamily.text,
  },
  footerLogo: {
    width: '100%',
    marginTop: '1rem',
    [theme.breakpoints.up('tablet')]: {
      width: '250px',
      height: '75px',
      marginTop: '3rem',
      padding: '5px',
    },
    [theme.breakpoints.up('smallLaptop')]: {
      width: '300px',
      height: '100px',
      marginTop: '3rem',
      padding: '5px',
    },
    [theme.breakpoints.up('macbook')]: {
      width: '300px',
      height: '100px',
      marginTop: '3rem',
      padding: '5px',
    },
    [theme.breakpoints.up('laptop')]: {
      width: '300px',
      height: '100px',
      marginTop: '3rem',
      padding: '5px',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '300px',
      height: '100px',
      marginTop: '3rem',
      padding: '5px',
    },
  },
  services: {
    marginTop: '2rem',
    overflow: 'hidden',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
    '& a': {
      [theme.breakpoints.down('md')]: {
        float: 'none',
        display: 'block',
      },
      color: 'white',
      padding: '0',
      textDecoration: 'none',
      fontSize: theme.fontSize.footerLink,
      display: 'block',
      lineHeight: '2.5rem',
      fontFamily: theme.fontFamily.text,
      '&:hover': {
        borderBottom: '2px solid white',
      },
    },
    '& p': {
      [theme.breakpoints.down('md')]: {
        float: 'none',
        display: 'block',
      },
      color: 'white',
      padding: '0',
      textDecoration: 'none',
      fontSize: theme.fontSize.p,
      fontFamily: theme.fontFamily.text,
    },
  },
  icons: {
    margin: '1rem 1rem 1rem 0',
    color: theme.background.green,
  },
  iconContainer: {
    '& a': {
      '&:hover, &:focus': {
        border: 'none',
        fontSize: '17px',
      },
    },
  },
}))

const FooterNew = () => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 500) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  })

  return (
    <div className={classes.container}>
      <Container>
        <Row>
          <Col md={true}>
            <img
              src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/CogentHubLogoCompressed.png"
              className={classes.footerLogo}
            />
          </Col>
        </Row>
        <Row>
          <Col md={true} className={classes.services}>
            <p
              style={{
                fontSize: '23px',
                fontWeight: 'bold',
                borderBottom: '2px solid #FFCB05',
              }}
            >
              Services
            </p>
            <a href="/services/front-office-management">
              Front Office Management
            </a>
            <a href="/services/back-office-management">
              Back Office Management
            </a>
            <a href="/services/knowledge-services">Knowledge Services</a>
            <a href="/services/integrated-bpo">Integrated BPO</a>
            <a href="/services/bureau-services">Bureau Services</a>
          </Col>
          <Col md={true} className={classes.services}>
            <p
              style={{
                fontSize: '23px',
                fontWeight: 'bold',
                borderBottom: '2px solid #FFCB05',
              }}
            >
              Industries
            </p>
            <a href="/industries/retail">Fashion & Retail</a>
            <a href="/industries/travel-tech">Travel Tech</a>
            <a href="/industries/ecommerce">Ecommerce</a>
            <a href="/industries/food-tech">Food Tech</a>
            <a href="/industries/ed-tech">Ed Tech</a>
          </Col>
          <Col md={true} className={classes.services}>
            <p
              style={{
                fontSize: '23px',
                fontWeight: 'bold',
                borderBottom: '2px solid #FFCB05',
              }}
            >
              Insights
            </p>
            <a href="#">Case Studies</a>
            <a href="/blogs">Blogs</a>
            <p
              style={{
                fontSize: '23px',
                fontWeight: 'bold',
                marginTop: '2.3rem',
                borderBottom: '2px solid #FFCB05',
              }}
            >
              Get In Touch
            </p>
            <p>info@cogenthub.com</p>
          </Col>
          <Col md={true} className={classes.services}>
            <p
              style={{
                fontSize: '23px',
                fontWeight: 'bold',
                borderBottom: '2px solid #FFCB05',
              }}
            >
              About Us
            </p>
            <a href="/career">Join Our Family</a>
            <a href="/contact-us">Contact Us</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <div className={classes.iconContainer}>
              <a
                style={{ display: 'inline' }}
                href="https://www.linkedin.com/company/cogenthub/"
              >
                <LinkedInIcon className={classes.icons} />
              </a>
              <a
                style={{ display: 'inline' }}
                href="https://twitter.com/hubcogent"
              >
                <TwitterIcon className={classes.icons} />
              </a>
              <a
                style={{ display: 'inline' }}
                href="https://www.facebook.com/CogentHubOutsourcing/"
              >
                <FacebookIcon className={classes.icons} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FooterNew

import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/styles'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useWindowDimensions from '../../useWindowDimensions'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0.5rem',
    [theme.breakpoints.up('macbook')]: {
      margin: '2rem',
    },
    [theme.breakpoints.up('laptop')]: {
      margin: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      margin: '2rem',
    },
  },
  serviceBox: {
    backgroundColor: '#EBF3C1',
    borderRadius: '20px',
    marginBottom: '1rem',
    padding: '10px',
    height: '80rem',
    [theme.breakpoints.up('mobileM')]: {
      height: '76rem',
      padding: '20px',
    },
    [theme.breakpoints.up('mobile')]: {
      height: '73rem',
      padding: '20px',
    },
    [theme.breakpoints.up('middleLaptop')]: {
      height: '75rem',
      margin: '1rem',
    },
    [theme.breakpoints.up('macbook')]: {
      height: '72rem',
      margin: '1rem',
    },
    [theme.breakpoints.up('laptop')]: {
      height: '72rem',
      padding: '1rem',
      margin: '1rem',
    },
    [theme.breakpoints.up('desktop')]: {
      height: '72rem',
      margin: '1rem',
    },
  },
  servicePoints: {
    // padding: '1.5rem',
    // width:"11",
    padding: '10px',
    fontFamily: theme.fontFamily.text,
    [theme.breakpoints.up('smallLaptop')]: {
      padding: '2rem',
      height: '32rem',
    },
    [theme.breakpoints.up('macbook')]: {
      padding: '2rem',
      height: '32rem',
    },
    [theme.breakpoints.up('laptop')]: {
      padding: '2rem',
      height: '32rem',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '2rem',
      height: '32rem',
    },
  },
  img: {
    paddingTop: '20px',
    textAlign: 'center',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.up('smallLaptop')]: {
      marginTop: '5rem',
    },
    // [theme.breakpoints.up('macbook')]: {
    //   padding: '2rem',
    // },
    // [theme.breakpoints.up('laptop')]: {
    //   padding: '2rem',
    // },
    // [theme.breakpoints.up('desktop')]: {
    //   padding: '2rem',
    // },
  },
  title: {
    fontWeight: 'bold',
    color: '#064621',
    fontFamily: theme.fontFamily.text,
  },
  learnMore: {
    borderRadius: '20px',
    color: 'white',
    fontFamily: theme.fontFamily.text,
    [theme.breakpoints.up('smallLaptop')]: {
      marginTop: '9rem',
    },
    [theme.breakpoints.up('macbook')]: {
      marginTop: '1rem',
    },
    [theme.breakpoints.up('laptop')]: {
      marginTop: '1rem',
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: '1rem',
    },
  },
  titleMain: {
    textAlign: 'center',
    '& h1': {
      color: '#064621',
      fontWeight: 'bold',
      fontSize: '48px',
      fontFamily: theme.fontFamily.heading,
    },
    '& h4': {
      color: '#575757',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.text,
    },
  },
}))

const AdvertServices = ({ inView, setInView }) => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 1025) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  }, [width])

  return (
    <div className={classes.container}>
      <div className={classes.titleMain}>
        <h1>Our Services</h1>
        <h4>Which will maximize your customer satisfaction </h4>
      </div>
      <Container>
        <Row>
          <Col md={6}>
            <div className={classes.serviceBox}>
              <div className={classes.img}>
                <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Frame+108.png" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h4 className={classes.title}>Integrated BPO</h4>
              </div>
              <ul className={classes.servicePoints}>
                <li>
                  <b>Customer Retention</b>- We identify and resolve customer
                  grievances with our timely quality assessment.
                </li>
                <br />
                <li>
                  <b>Customer Service </b>- With our sales call center services,
                  we ensure the customer gets the value for their
                  product/service, and the business acquires the valuable
                  insights that can help them cater to their needs better.
                </li>
                <br />
                <li>
                  <b>Live Chat and Email Support </b>- Cater to your Gen Z
                  clientele with customer Services such as Live Chat and Email
                  Support.
                </li>
                <br />
                <li>
                  <b>Agile Framework </b>- With our agile customer management
                  framework, we ensure consumer concerns are attended to and
                  addressed at the right time. Our Integrated BPO platform
                  offers an integrated system to ensure that the customer's
                  info, record and communication activity is linked to the back
                  end. It provides the delivery centre with a complete view of
                  the customer's journey so the agents can access the required
                  information to quickly resolve issues.
                </li>
              </ul>
              <div style={{ textAlign: 'center' }}>
                <Button
                  size="lg"
                  variant="warning"
                  className={classes.learnMore}
                  href="/services/integrated-bpo"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.serviceBox}>
              <div className={classes.img}>
                <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-KPO.png" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h4 className={classes.title}>Knowledge Process Outsourcing</h4>
              </div>
              <ul className={classes.servicePoints}>
                <li>
                  <b>Lead Generation </b>- Target your most qualified audience
                  by obtaining a high influx of quality leads with better
                  conversion rates.
                </li>
                <br />
                <li>
                  <b>Customer Data Analytics</b> - We help you understand the
                  customer lifecycle better and improve customer insight across
                  the customer journey to provide personalized services.
                </li>
                <br />
                <li>
                  <b>Data Collection </b>- Our email support can be deployed to
                  gather highly target actionable marketing insights.
                </li>
                <br />
                <li>
                  <b>Market and Business Research </b>- With social media market
                  research, we gather insights and measure your brand’s overall
                  reputation in the market to help you strategize better.
                </li>
                <br />
                <li>
                  <b>Customer Satisfaction Analysis </b>- Through thorough
                  customer data analysis, we help our clients understand the
                  impact of a process in their business. Using a critical
                  strategic pillar, CogentHub focuses on enhancing
                  time-to-market and organisational effectiveness.
                </li>
                <br />
              </ul>
              <div style={{ textAlign: 'center' }}>
                <Button
                  size="lg"
                  variant="warning"
                  className={classes.learnMore}
                  href="/services/knowledge-services"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.serviceBox}>
              <div className={classes.img}>
                <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-Back+Office+Management+System.png" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h4 className={classes.title}>
                  Back Office Management Services
                </h4>
              </div>
              <ul className={classes.servicePoints}>
                <li>
                  <b>Seamless Customer Support </b>- With our digital tools and
                  cutting edge solutions, we can provide faster resolutions to
                  your customers.
                </li>
                <br />
                <li>
                  <b>Complaint Management </b>- We take customer complaints
                  seriously and scrutinize them thoroughly to bridge potential
                  communication gaps.
                </li>
                <br />
                <li>
                  <b>Order Support </b>- From product queries to order delivery,
                  with our highly scalable email support service, you can keep
                  the customers in sync with the latest updates related to their
                  orders.
                </li>
                <br />
                <li>
                  <b>Customer Loyalty Management </b>- From managing customer
                  databases to categorizing potential customers for various
                  benefits – we do it all by effectively keeping track of all
                  consumer activities across touchpoints.
                </li>
                <br />
                <li>
                  <b>Data Processing </b>- Manage your customer information
                  seamlessly with our guaranteed data security protocols (ISO
                  27001)
                </li>
              </ul>
              <div style={{ textAlign: 'center' }}>
                <Button
                  size="lg"
                  variant="warning"
                  className={classes.learnMore}
                  href="/services/back-office-management"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.serviceBox}>
              <div className={classes.img}>
                <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-Front+Office+Management+Services.png" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h4 className={classes.title}>
                  Front Office Management Services
                </h4>
              </div>
              <ul className={classes.servicePoints}>
                <li>
                  <b> Helpdesk Solutions </b>- We offer tailor-made help desk
                  outsourcing solutions to our clients to promptly resolve
                  issues and improve customer satisfaction ratings.
                </li>
                <br />
                <li>
                  <b>Customer Service </b>- Manage your business the right way
                  with our premium customer service which puts customers at the
                  top of our priority list.
                </li>
                <br />
                <li>
                  <b>Query Management </b>- You can effectively manage a large
                  volume of queries when you outsource email support services,
                  reducing the burden on other channels.
                </li>
                <br />
                <li>
                  <b>Technical Support </b>- Our certified engineers are capable
                  of providing 24/7 tech support services in a comprehensive
                  manner across channels. Our solutions will help you increase
                  your revenue, improve your customer satisfaction and working
                  capital management. It will further help you expand your
                  margins and reduce your agitating rates. These services
                  include sales, marketing, customer service, tech support, and
                  any business procedure demanding direct contact with
                  customers.
                </li>
              </ul>
              <div style={{ textAlign: 'center' }}>
                <Button
                  size="lg"
                  variant="warning"
                  className={classes.learnMore}
                  href="/services/front-office-management"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.serviceBox}>
              <div className={classes.img}>
                <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-Bureau+Services.png" />
              </div>
              <div style={{ textAlign: 'center' }}>
                <h4 className={classes.title}>Bureau Services</h4>
              </div>
              <ul className={classes.servicePoints}>
                <li>
                  <b>Order Support </b>- Live chat support services enable your
                  business to send text confirmation for order verification,
                  purchase detail, tracking status, and various other queries
                  related to the order cycle seamlessly.
                </li>
                <br />
                <li>
                  <b>Customer Engagement </b>- Make your customers feel special
                  with a choice of channels such as calling, email and chat
                  support.
                </li>
                <br />
                <li>
                  <b>Social Brand Engagement </b>- We help you make the presence
                  of your brand visible with social media engagement activities.
                </li>
                <br />
                <li>
                  <b>Customer Service Emails </b>- We can help you to send
                  auto-responses, follow-ups, feedback emails, on-board your
                  newcomers with welcome emails, and much more. That will
                  improve your relationships with the customers and take your
                  customer service to the next level. With our round-the-clock
                  customer support, never lose an opportunity to cater to your
                  clients worldwide. At CogentHub, we will provide instant
                  customer replies, manage social media profiles for customer
                  support and provide practical, timely solutions over email.
                </li>
                <br />
              </ul>
              <div style={{ textAlign: 'center' }}>
                <Button
                  size="lg"
                  variant="warning"
                  className={classes.learnMore}
                  href="/services/bureau-services"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AdvertServices

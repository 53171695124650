import { React, useState, useEffect } from 'react'
import AdvertHeader from '../Header/AdvertHeader'
import ScrollAnimation from 'react-animate-on-scroll'
import AdvertBanner from '../BannerCarousel/AdvertBanner'
import AdvertServices from '../AdvertServices/AdvertServices'
import AdvertWrapper from '../HomeSectionTwo/AdvertWrapper'
import AdvertWrapperTwo from '../HomeSectionTwo/AdvertWrapperTwo'
import AdvertCarousel from '../AdvertCaraousel/AdvertCaraousel'
import Awards from '../Awards/Awards'
import FooterNew from '../Footer/FooterNew'
import AdvertGlobalReach from '../AdvertGlobalReach/AdvertGlobalReach'
import { makeStyles } from '@material-ui/styles'
import AOS from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles((theme) => ({
  mainBackground: {
    backgroundColor: 'white',
  },
}))

function AdvertLandingPage() {
  const classes = useStyles()
  const [wrapperInView, setWrapperInView] = useState(false)
  const [benefitsInView, setBenefitsInView] = useState(false)

  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
  }, [])

  return (
    <div className={classes.mainBackground}>
      <div className={classes.header}>
        <AdvertHeader />
      </div>
      {/* <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        <BannerCaraousel />
      </ScrollAnimation> */}
      <AdvertBanner />
      <div
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
        style={{ marginTop: '150px' }}
      >
        <AdvertWrapper />
      </div>
      <div
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
        style={{ marginTop: '150px' }}
      >
        <AdvertWrapperTwo />
      </div>
      <div
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
        style={{ marginTop: '150px' }}
      >
        <Awards />
      </div>
      <div
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
        style={{ marginTop: '150px' }}
      >
        <AdvertServices />
      </div>
      {/* <div
        data-aos="fade-right"
        data-aos-offset="600"
        data-aos-easing="ease-in-sine"
        style={{ marginTop: '150px' }}
      >
        <AdvertCarousel />
      </div> */}
      <div
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
        style={{ marginTop: '150px' }}
      >
        <AdvertGlobalReach />
      </div>
      <FooterNew />
    </div>
  )
}

export default AdvertLandingPage

import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/styles'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useWindowDimensions from '../../useWindowDimensions'

const useStyles = makeStyles((theme) => ({
  informationBox: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    minHeight: '250px',
    textAlign: 'center',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
    padding: '2rem',
    '& h1': {
      fontWeight: theme.fontWeight.bold,
    },
    // marginBottom: '6rem',
    // marginTop: '6rem',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
    padding: '10px 10px 10px 10px',
    borderRadius: '30px',
    backgroundColor: theme.background.yellow,
    '& a': {
      textDecoration: 'none',
      color: 'white',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    width: '40%',
    fontFamily: theme.fontFamily.text,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '700px',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '800px',
      height: '600px',
    },
  },
  learnMore: {
    borderRadius: '20px',
    color: 'white',
    [theme.breakpoints.up('smallLaptop')]: {
      marginLeft: '1.5rem',
    },
    [theme.breakpoints.up('macbook')]: {
      marginLeft: '1.5rem',
    },
    [theme.breakpoints.up('laptop')]: {
      marginLeft: '1.5rem',
    },
    [theme.breakpoints.up('desktop')]: {
      marginLeft: '1.5rem',
    },
    fontFamily: theme.fontFamily.text,
    width: '290px',
    height: '51px',
  },
  title: {
    '& h1': {
      fontFamily: theme.fontFamily.heading,
    },
    '& p': {
      fontFamily: theme.fontFamily.text,
    },
  },
  phoneContainer: {
    textAlign: 'center',
  },
  phoneTitle: {
    padding: '1rem',
    textAlign: 'center',
    [theme.breakpoints.up('smallLaptop')]: {
      textAlign: 'left',
    },
    fontFamily: theme.fontFamily.text,
  },
  titleMain: {
    textAlign: 'center',
    '& h1': {
      fontSize: '48px',
      color: '#064621',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.heading,
    },
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: '1rem',
    '& h4': {
      color: '#575757',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.text,
    },
  },
  point: {
    marginTop: '7px',
    fontSize: '14px',
  },
  pointContainer: {
    margin: '5px',
    fontSize: '14px',
  },
  phoneImg: {
    width: '300px',
    [theme.breakpoints.up('mobile')]: {
      width: '400px',
    },
  },
}))

const AdvertWrapper = ({ inView, setInView }) => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 1025) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  }, [width])

  return (
    <div className={classes.container}>
      <Container>
        {!phoneView && (
          <div>
            <div className={classes.titleMain}>
              <h1>Why Choose CogentHub</h1>
            </div>
            <div className={classes.subTitle}>
              <h4>
                CogentHub’s research model add values to enhance the business of
                its customers
              </h4>
            </div>
            <Row>
              <Col xs={6}>
                <img
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+section+-why+choose+cogent+hub.svg"
                  className={classes.img}
                />
              </Col>
              <Col className={classes.title} xs={6}>
                <p style={{ fontSize: '16px', marginLeft: '1.5rem' }}>
                  Businesses encounter several challenges during their
                  lifecycle. At CogentHub, we help to address these hurdles by
                  contributing unparalleled services in management consulting.
                  We harness the most advanced technology towards delivering a
                  delightful experience for your customers. CogentHub achieves
                  excellence by deploying immaculately planned operations
                  without any compromise.
                  <br /> CogentHub presents a wide array of services ranging
                  from front-office management. We continue to services clients
                  in the Retail, Telecom, Finance, Utilities, Healthcare and
                  Travel industries. We have thoughtfully created a passionate
                  group of professionals that prides itself in delivering
                  objectives set out by you. This team subscribes itself to your
                  thought process and goals and endeavours to effectively fuel
                  your business growth.
                  <br />
                  <Row className={classes.pointContainer}>
                    <Col md={1}>
                      <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-check.png" />
                    </Col>
                    <Col md={11} className={classes.point}>
                      We provide ISO 2770 certified data security.
                    </Col>
                  </Row>
                  <Row className={classes.pointContainer}>
                    <Col md={1}>
                      <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-check.png" />
                    </Col>
                    <Col md={11} className={classes.point}>
                      We have a young, vibrant and energetic team working 24×7.
                    </Col>
                  </Row>
                  <Row className={classes.pointContainer}>
                    <Col md={1}>
                      <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-check.png" />
                    </Col>
                    <Col md={11} className={classes.point}>
                      We provide personalized human touch is customer support.
                    </Col>
                  </Row>
                </p>
                <div>
                  <Button
                    size="lg"
                    variant="warning"
                    className={classes.learnMore}
                    href="/about-us"
                  >
                    Contact Us
                  </Button>{' '}
                </div>
              </Col>
            </Row>
          </div>
        )}
        {phoneView && (
          <div>
            <div className={classes.titleMain}>
              <h1>Why Choose CogentHub</h1>
            </div>
            <div className={classes.subTitle}>
              <h4>
                CogentHub’s research model add values to enhance the business of
                its customers
              </h4>
            </div>
            <Row>
              <Col>
                <img
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+section-why+choose+CH+(1).png"
                  className={classes.phoneImg}
                />
              </Col>
              <Col className={classes.phoneTitle}>
                <p style={{ fontSize: '16px' }}>
                  Businesses encounter several challenges during their
                  lifecycle. At CogentHub, we help to address these hurdles by
                  contributing unparalleled services in management consulting.
                  We harness the most advanced technology towards delivering a
                  delightful experience for your customers. CogentHub achieves
                  excellence by deploying immaculately planned operations
                  without any compromise. CogentHub presents a wide array of
                  services ranging from front-office management. We continue to
                  services clients in the Retail, Telecom, Finance, Utilities,
                  Healthcare and Travel industries. We have thoughtfully created
                  a passionate group of professionals that prides itself in
                  delivering objectives set out by you. This team subscribes
                  itself to your thought process and goals and endeavours to
                  effectively fuel your business growth.
                  <Row className={classes.pointContainer}>
                    <Col md={1}>
                      <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-check.png" />
                    </Col>
                    <Col md={11} className={classes.point}>
                      We provide ISO 2770 certified data security.
                    </Col>
                  </Row>
                  <Row className={classes.pointContainer}>
                    <Col md={1}>
                      <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-check.png" />
                    </Col>
                    <Col md={11} className={classes.point}>
                      We have a young, vibrant and energetic team working 24×7.
                    </Col>
                  </Row>
                  <Row className={classes.pointContainer}>
                    <Col md={1}>
                      <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Icon-check.png" />
                    </Col>
                    <Col md={11} className={classes.point}>
                      We provide personalized human touch is customer support.
                    </Col>
                  </Row>
                </p>
                <div>
                  <Button
                    size="lg"
                    variant="warning"
                    className={classes.learnMore}
                    href="/about-us"
                  >
                    Contact Us
                  </Button>{' '}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  )
}

export default AdvertWrapper

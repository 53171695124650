import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/styles'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import HeaderTemp from '../Header/HeaderTemp'
import Banner from '../BannerCarousel/Banner'
import FooterNew from '../Footer/FooterNew'
import useWindowDimensions from '../../useWindowDimensions'
const axios = require('axios')

const useStyles = makeStyles((theme) => ({
  contacts: {
    margin: '1rem',
    padding: '1rem',
  },
}))

const Contacts = ({ inView, setInView }) => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [queries, setQueries] = useState([])

  useEffect(() => {
    if (width < 425) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  }, [width])

  useEffect(() => {
    setLoading(true)
    axios
      .get('https://cogenthub-api.herokuapp.com/contact/getQueries')
      .then(function (response) {
        setLoading(false)
        setQueries(response.data.query)
        console.log('Storage', response)
      })
      .catch((error) => {
        setLoading(false)
        console.log('Storage', error.response)
      })
  }, [])

  return (
    <div className={classes.container}>
      <HeaderTemp />
      <Banner title="Contacts" subTitle="" />
      <div className={classes.contacts}>
        <Row>
          {queries.map((query) => {
            return (
              <>
                <Col md={true}>
                  <Card style={{ width: '18rem' }}>
                    <Card.Header>Contact</Card.Header>
                    <Card.Body>
                      <Card.Text>First Name : {query.firstName}</Card.Text>
                      <Card.Text>Last Name : {query.lastName}</Card.Text>
                      <Card.Text>Email : {query.email}</Card.Text>
                      <Card.Text>Number : {query.number}</Card.Text>
                      <Card.Text>Message : {query.message}</Card.Text>
                      <Button variant="primary">{query.time}</Button>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )
          })}
        </Row>
      </div>
      <FooterNew />
    </div>
  )
}

export default Contacts

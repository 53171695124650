import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/styles'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useWindowDimensions from '../../useWindowDimensions'

const useStyles = makeStyles((theme) => ({
  informationBox: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    minHeight: '250px',
    textAlign: 'center',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
    padding: '2rem',
    '& h1': {
      fontWeight: theme.fontWeight.bold,
    },
    // marginBottom: '6rem',
    // marginTop: '6rem',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
    padding: '10px 10px 10px 10px',
    borderRadius: '30px',
    backgroundColor: theme.background.yellow,
    '& a': {
      textDecoration: 'none',
      color: 'white',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    width: '40%',
    fontFamily: theme.fontFamily.text,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '700px',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '800px',
      height: '600px',
    },
  },
  learnMore: {
    borderRadius: '20px',
    color: 'white',
    // marginLeft: '1.5rem',
    fontFamily: theme.fontFamily.text,
    width: '290px',
    height: '51px',
    [theme.breakpoints.up('smallLaptop')]: {},
    [theme.breakpoints.up('middleLaptop')]: {
      marginLeft: '1rem',
    },
    [theme.breakpoints.up('macbook')]: {
      marginTop: '4rem',
      marginLeft: '1rem',
    },
    [theme.breakpoints.up('laptop')]: {
      marginTop: '4rem',
      marginLeft: '1rem',
    },
    [theme.breakpoints.up('desktop')]: {},
  },
  title: {
    marginTop: '6rem',
    [theme.breakpoints.up('smallLaptop')]: {
      marginTop: '1rem',
    },
    '& h1': {
      fontFamily: theme.fontFamily.heading,
      fontSize: '48px',
    },
    '& p': {
      fontFamily: theme.fontFamily.text,
    },
  },
  phoneContainer: {
    textAlign: 'center',
  },
  phoneTitle: {
    borderBottom: '2px solid #FFCB05',
    padding: '1rem',
    fontFamily: theme.fontFamily.heading,
  },
  titleMain: {
    textAlign: 'center',
    '& h1': {
      color: '#064621',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.heading,
      fontSize: '48px',
    },
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: '3rem',
    '& h4': {
      color: '#575757',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.text,
    },
  },
  point: {
    marginTop: '7px',
    fontSize: '17px',
  },
  pointContainer: {
    margin: '5px',
  },
  phoneImg: {
    width: '290px',
    [theme.breakpoints.up('mobile')]: {
      width: '400px',
    },
  },
  phoneText: {
    textAlign: 'center',
    [theme.breakpoints.up('smallLaptop')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('middleLaptop')]: {
      textAlign: 'left',
      marginTop: '8rem',
    },
    [theme.breakpoints.up('macbook')]: {
      textAlign: 'left',
      marginTop: '7rem',
    },
    [theme.breakpoints.up('laptop')]: {
      textAlign: 'left',
      marginTop: '7rem',
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'left',
    },
  },
}))

const AdvertWrapper = ({ inView, setInView }) => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 1025) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  }, [width])

  return (
    <div className={classes.container}>
      <Container>
        {!phoneView && (
          <div>
            <div className={classes.titleMain}>
              <h1>Digital superpowers at your fingertips</h1>
            </div>
            <div className={classes.subTitle}>
              <h4>
                <span>
                  We deliver cutting-edge business process outsourcing services
                  using
                </span>
                <br />
                <span>
                  our experience across several industries and expertise in
                  technology
                </span>
              </h4>
            </div>
            <Row>
              <Col xs={6}>
                <img
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+section-digital+superpowers+at+your+fingertips.png"
                  className={classes.img}
                />
              </Col>
              <Col className={classes.title} xs={6}>
                <p
                  style={{ fontSize: '16px', marginLeft: '1.5rem' }}
                  className={classes.phoneText}
                >
                  <span>
                    CogentHub is an upcoming NASSCOM accredited, ISO certified
                    globally focussed ITeS/BPO Services & Digital Transformation
                    Consulting Company. It has a state of the art business
                    facilities, skilled manpower and proven industry best
                    practices/ processes headquartered in Kolkata, India.
                  </span>
                  <br />
                  <span>
                    CogentHub specializes in outbound as well as inbound (Voice
                    and Non-Voice) business processes. We assist our clients in
                    accomplishing their tactical and strategic business goals by
                    using a blend of digitally experienced contact centre
                    specialists, agent-assisted automation technology, and
                    proven IT processes, tools, and infrastructure.
                  </span>
                </p>
                <div>
                  <Button
                    size="lg"
                    variant="warning"
                    className={classes.learnMore}
                    href="/about-us"
                  >
                    Contact Us
                  </Button>{' '}
                </div>
              </Col>
            </Row>
          </div>
        )}
        {phoneView && (
          <div>
            <div className={classes.titleMain}>
              <h1>Digital superpowers at your fingertips</h1>
            </div>
            <div className={classes.subTitle}>
              <h4>
                We deliver cutting-edge business process outsourcing services
                using our experience across several industries and expertise in
                technology
              </h4>
            </div>
            <Row>
              <Col>
                <img
                  src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+section-digital+superpower+at+your+fingertips.png"
                  className={classes.phoneImg}
                />
              </Col>
              <Col className={classes.title}>
                <p style={{ fontSize: '19px' }} className={classes.phoneText}>
                  CogentHub is an upcoming NASSCOM accredited, ISO certified
                  globally focussed ITeS/BPO Services & Digital Transformation
                  Consulting Company. It has a state of the art business
                  facilities, skilled manpower and proven industry best
                  practices/ processes headquartered in Kolkata, India.
                </p>
                <p style={{ fontSize: '19px' }} className={classes.phoneText}>
                  CogentHub specializes in outbound as well as inbound (Voice
                  and Non-Voice) business processes. We assist our clients in
                  accomplishing their tactical and strategic business goals by
                  using a blend of digitally experienced contact centre
                  specialists, agent-assisted automation technology, and proven
                  IT processes, tools, and infrastructure.
                </p>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    size="lg"
                    variant="warning"
                    className={classes.learnMore}
                    href="/about-us"
                  >
                    Contact Us
                  </Button>{' '}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  )
}

export default AdvertWrapper

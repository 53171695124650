import { React, useState, useEffect } from 'react'
import HeaderTemp from '../Header/HeaderTemp'
import FooterNew from '../Footer/FooterNew'
import ServiceBanner from '../Services/ServiceBanner'
import ServiceConsultation from '../Services/ServiceConsultation'
import ServiceSectionTwo from '../Services/ServicesSectionTwo'
import ServiceSectionThree from '../Services/ServicesSectionThree'
import GloballyRenowned from '../GloballyRenowned/GloballyRenowned'
import ServiceFaq from '../Services/ServiceFaq'
import AOS from 'aos'
import { Helmet } from 'react-helmet'
import 'aos/dist/aos.css'

function ServicesPage({ service, isHeader, setIsHeader }) {
  const [serviceType, setService] = useState({})

  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
  }, [])

  return (
    <>
      <div>
        <Helmet>
          <title>{service.title}</title>
          <meta name="description" content={service.description} />
          <meta name="robots" content={service.robots} />
          <meta property="og:title" content={service.ogTitle} />
          <meta property="og:site_name" content={service.ogSiteName} />
          <meta property="og:url" content={service.ogUrl} />
          <meta property="og:description" content={service.ogDescription} />
          <meta property="og:type" content={service.ogType} />
          {service.ogImage.map((image) => {
            return <meta property="og:image" content={image} />
          })}
        </Helmet>
        <div>
          <HeaderTemp setIsHeader={setIsHeader} isHeader={isHeader} />
        </div>
        <div
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <ServiceBanner service={service} isHeader={isHeader} />
        </div>
        {/* <div>
        <ServiceAdvantages service={service} />
      </div> */}
        <div data-aos="fade-right" data-aos-easing="ease-in-sine">
          <ServiceSectionTwo service={service} />
        </div>
        <div data-aos="fade-right" data-aos-easing="ease-in-sine">
          {serviceType != undefined && (
            <ServiceSectionThree service={service} />
          )}
        </div>
        <div
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          style={{
            marginTop: '4rem',
          }}
        >
          <ServiceConsultation />
        </div>
        <div
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          style={{
            backgroundColor: '#FDF7E6',
            padding: '1rem',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              paddingTop: '2rem',
              fontWeight: 'bold',
              fontSize: '48px',
              fontFamily: 'Playfair Display',
            }}
          >
            <h1>Frequently Asked Questions</h1>
          </div>
          <ServiceFaq service={service} />
        </div>
        <div
          style={{ backgroundColor: '#FDF7E6' }}
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <GloballyRenowned />
        </div>
        {/* <div>
        <ServicesSolutions service={service} />
      </div> */}
        <FooterNew />
      </div>
    </>
  )
}

export default ServicesPage

import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import content from '../../ContentJson/content.json'
import { makeStyles } from '@material-ui/styles'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Card from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.css'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveIcon from '@material-ui/icons/Remove'
import Faq from './Faq'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0',
    [theme.breakpoints.up('smallLaptop')]: {
      margin: '1rem',
    },
  },
  accordianContainer: {
    width: '100%',
  },
  header: {
    width: '100%',
    padding: '1rem',
    backgroundColor: '#EBF3C1',
  },
  text: {
    fontFamily: theme.fontFamily.text,
  },
}))

export default function ServiceFaq({ service, isHeader }) {
  const classes = useStyles()

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!'),
    )
    return (
      <div type="button" className={classes.header} onClick={decoratedOnClick}>
        {children}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {service.Faq.map((faq) => {
        return (
          <div className={classes.accordianContainer}>
            <Faq
              question={faq.Question}
              body={faq.Body}
              icon={faq.Icon}
              eventKey={faq.EventKey}
            />
          </div>
        )
      })}
    </div>
  )
}

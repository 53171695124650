import { React, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
const axios = require('axios')
import { useSpring, animated } from 'react-spring'
import Test from './HeaderItems/Insights'
import Services from './HeaderItems/Services'
import Industries from './HeaderItems/Industries'
import Achievements from './HeaderItems/Achievements'
import Career from './HeaderItems/Career'
import Discover from './HeaderItems/Discover'
import Add from './HeaderItems/Add'
import MenuIcon from '@material-ui/icons/Menu'
import useWindowDimensions from '../../useWindowDimensions'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import OffcanvasHeader from 'react-bootstrap/OffcanvasHeader'
import Offcanvas from 'react-bootstrap/Offcanvas'
import OffcanvasTitle from 'react-bootstrap/OffcanvasTitle'
import OffcanvasBody from 'react-bootstrap/OffcanvasBody'
import { ReactComponent as YourSvg } from '../../logo.svg'

const useStyles = makeStyles((theme) => ({
  burger: {
    padding: '15px 10px 0 10px',
    margin: 0,
    '& a': {
      color: 'white',
    },
    position: 'absolute',
    zIndex: '150',
    right: '0',
  },
  leftAlign: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    position: 'absolute',
    zIndex: '2000',
    width: '100%',
    boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px',
    overflow: 'hidden',
    fontWeight: theme.fontWeight.light,
    fontFamily: theme.fontFamily.text,
    backgroundColor: 'rgba(0,0,0,0.5)',
    '& a': {
      float: 'left',
      color: 'white',
      textAlign: 'center',
      padding: '14px 16px',
      margin: '1.7rem 1.5rem 0 1.5rem',
      textDecoration: 'none',
      fontSize: theme.fontSize.text,
      fontWeight: '400',
    },
    '& p': {
      float: 'left',
      color: 'white',
      margin: '0.5rem 0 0 0',
      textAlign: 'center',
      padding: '14px 16px',
      textDecoration: 'none',
      fontSize: theme.fontSize.text,
      fontWeight: theme.fontWeight.bold,
    },
  },
  rightAlign: {
    overflow: 'hidden',
    '& a': {
      float: 'right',
      color: 'white',
      textAlign: 'center',
      padding: '10px 16px',
      margin: '2rem 1.5rem 0 1.5rem',
      textDecoration: 'none',
      fontSize: theme.fontSize.p,
      width: '100px',
      height: '45px',
    },
    '& p': {
      float: 'right',
      color: 'black',
      margin: '2.5rem 0 0 0',
      textAlign: 'center',
      padding: '14px 16px',
      textDecoration: 'none',
      fontSize: theme.fontSize.p,
      fontWeight: theme.fontWeight.bold,
    },
  },
  sideBarBody: {
    '& a': {
      textDecoration: 'none',
      display: 'block',
      padding: '10px',
      color: 'black',
      fontSize: theme.fontSize.p,
    },
  },
  sideBarTitle: {
    '& a': {
      '& img': {
        textDecoration: 'none',
        color: 'black',
        width: '95%',
      },
    },
  },
  phoneList: {
    '& a': {
      fontSize: '14px',
      padding: '10px',
    },
  },
  logo: {
    width: '100%',
    height: '70px',
  },
  logoLink: {
    marginTop: '0.25rem !important',
    padding: '10px !important',
  },
  modal: {
    textAlign: 'center',
  },
  button: {
    backgroundColor: theme.background.yellow,
    borderRadius: '20px',
    color: 'white',
    marginTop: '10px',
  },
  dropdown: {
    position: 'absolute',
    width: '100%',
    marginTop: '90px',
    zIndex: 10000000,
    fontFamily: theme.fontFamily.text,
  },
  headerItem: {
    '&:hover, &:focus': {
      borderBottom: '5px solid #FFCB05',
    },
    color: 'white',
    fontFamily: theme.fontFamily.text,
  },
  phoneLogo: {
    width: '150px',
    float: 'left',
    padding: '15px 10px 0 10px',
    position: 'relative',
    zIndex: '10',
  },
  phoneContainer: {
    width: '100%',
    position: 'absolute',
  },
}))

const HeaderTemp = ({ setIsHeader, isHeader }) => {
  const classes = useStyles()
  const [add, setAdd] = useState(null)
  const [insights, setInsights] = useState(null)
  const [services, setServices] = useState(null)
  const [industries, setIndustries] = useState(null)
  const [achievements, setAchievements] = useState(null)
  const [career, setCareer] = useState(null)
  const [discover, setDiscover] = useState(null)
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)
  const [show, setShow] = useState(false)
  const [isAdmin, setAdmin] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    if (width < 1025) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
    let token = localStorage.getItem('token')
    console.log(token)
    axios
      .post('https://cogenthub-api.herokuapp.com/authorize', {
        token: token,
      })
      .then(function (response) {
        setAdmin(true)
        console.log(response)
      })
      .catch((error) => {
        console.log(error.message)
      })
    console.log('phoneView', phoneView)
    console.log('width', width)
  }, [width])

  const onInsights = () => {
    // if (
    //   services === true ||
    //   industries === true ||
    //   career === true ||
    //   discover === true ||
    //   add === true
    // ) {
    //   setIsHeader(true)
    // } else {
    //   setIsHeader(!isHeader)
    // }
    if (insights === null) {
      setInsights(true)
    } else {
      setInsights(!insights)
    }
    if (add != null) {
      setAdd(false)
    }
    if (services != null) {
      setServices(false)
    }
    if (industries != null) {
      setIndustries(false)
    }
    if (achievements != null) {
      setAchievements(false)
    }
    if (career != null) {
      setCareer(false)
    }
    if (discover != null) {
      setDiscover(false)
    }
  }

  const onServices = () => {
    // if (
    //   insights === true ||
    //   industries === true ||
    //   career === true ||
    //   discover === true ||
    //   add === true
    // ) {
    //   setIsHeader(true)
    // } else {
    //   setIsHeader(!isHeader)
    // }
    if (services === null) {
      setServices(true)
    } else {
      setServices(!services)
    }
    if (insights != null) {
      setInsights(false)
    }
    if (add != null) {
      setAdd(false)
    }
    if (industries != null) {
      setIndustries(false)
    }
    if (achievements != null) {
      setAchievements(false)
    }
    if (career != null) {
      setCareer(false)
    }
    if (discover != null) {
      setDiscover(false)
    }
  }

  const onIndustries = () => {
    // if (
    //   insights === true ||
    //   services === true ||
    //   career === true ||
    //   discover === true ||
    //   add === true
    // ) {
    //   setIsHeader(true)
    // } else {
    //   setIsHeader(!isHeader)
    // }
    if (industries === null) {
      setIndustries(true)
    } else {
      setIndustries(!industries)
    }
    if (insights != null) {
      setInsights(false)
    }
    if (add != null) {
      setAdd(false)
    }
    if (services != null) {
      setServices(false)
    }
    if (achievements != null) {
      setAchievements(false)
    }
    if (career != null) {
      setCareer(false)
    }
    if (discover != null) {
      setDiscover(false)
    }
  }

  const onAchievements = () => {
    if (achievements === null) {
      setAchievements(true)
    } else {
      setAchievements(!achievements)
    }
    if (add != null) {
      setAdd(false)
    }
    if (insights != null) {
      setInsights(false)
    }
    if (services != null) {
      setServices(false)
    }
    if (career != null) {
      setCareer(false)
    }
    if (discover != null) {
      setDiscover(false)
    }
    if (industries != null) {
      setIndustries(false)
    }
  }

  const onCareer = () => {
    // if (
    //   insights === true ||
    //   industries === true ||
    //   services === true ||
    //   discover === true ||
    //   add === true
    // ) {
    //   setIsHeader(true)
    // } else {
    //   setIsHeader(!isHeader)
    // }
    if (career === null) {
      setCareer(true)
    } else {
      setCareer(!career)
    }
    if (add != null) {
      setAdd(false)
    }
    if (insights != null) {
      setInsights(false)
    }
    if (services != null) {
      setServices(false)
    }
    if (achievements != null) {
      setAchievements(false)
    }
    if (discover != null) {
      setDiscover(false)
    }
    if (industries != null) {
      setIndustries(false)
    }
  }

  const onDiscover = () => {
    // s
    if (discover === null) {
      setDiscover(true)
    } else {
      setDiscover(!discover)
    }
    if (insights != null) {
      setInsights(false)
    }
    if (services != null) {
      setServices(false)
    }
    if (achievements != null) {
      setAchievements(false)
    }
    if (career != null) {
      setCareer(false)
    }
    if (industries != null) {
      setIndustries(false)
    }
  }

  const onAdd = () => {
    // if (
    //   insights === true ||
    //   industries === true ||
    //   career === true ||
    //   discover === true ||
    //   services === true
    // ) {
    //   setIsHeader(true)
    // } else {
    //   setIsHeader(!isHeader)
    // }
    if (add === null) {
      setAdd(true)
    } else {
      setAdd(!add)
    }
    if (services != null) {
      setServices(false)
    }
    if (insights != null) {
      setInsights(false)
    }
    if (industries != null) {
      setIndustries(false)
    }
    if (achievements != null) {
      setAchievements(false)
    }
    if (career != null) {
      setCareer(false)
    }
    if (discover != null) {
      setDiscover(false)
    }
  }

  return (
    <>
      {phoneView && (
        <div className={classes.phoneContainer}>
          <div className={classes.phoneLogoContainer}>
            <img src="/cogentLogo.svg" className={classes.phoneLogo} />
          </div>
          <div className={classes.burger}>
            <a href="#" onClick={handleShow}>
              <MenuIcon />
            </a>
            <Offcanvas
              show={show}
              onHide={handleClose}
              style={{ width: '70%' }}
              placement="end"
            >
              <Offcanvas.Header closeButton style={{ paddingBottom: '0' }}>
                <Offcanvas.Title className={classes.sideBarTitle}>
                  <a href="/">
                    <img
                      src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/CogentHubLogoCompressed.png"
                      style={{ width: '170px', height: '60px' }}
                    />
                  </a>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className={classes.sideBarBody}>
                <a href="#home" onClick={onInsights}>
                  Insights
                </a>
                {insights && (
                  <div className={classes.phoneList}>
                    <a href="/blogs">Blogs</a>
                    <a>Global Trend Studies</a>
                    <a>Case Studies</a>
                    <a>Podcasts on Spotify</a>
                  </div>
                )}
                <a href="#news" onClick={onServices}>
                  Services
                </a>
                {services && (
                  <div className={classes.phoneList}>
                    <a href="/services/integrated-bpo">Integrated BPO</a>
                    <a href="/services/knowledge-services">
                      Knowledge Services
                    </a>
                    <a href="/services/back-office-management">
                      Back Office Management
                    </a>
                    <a href="/services/front-office-management">
                      Front Office Management
                    </a>
                    <a href="/services/bureau-services">Bureau Services</a>
                  </div>
                )}
                <a href="#contact" onClick={onIndustries}>
                  Industries
                </a>
                {industries && (
                  <div className={classes.phoneList}>
                    <a href="/industries/ecommerce">E-Commerce</a>
                    <a href="/industries/retail">Fashion & Retail</a>
                    <a href="/industries/food-tech">Food Tech</a>
                    <a href="/industries/travel-tech">Travel Tech</a>
                    <a href="/industries/ed-tech">Ed Tech</a>
                  </div>
                )}
                <a href="#about" onClick={onCareer}>
                  Career
                </a>
                {career && (
                  <div className={classes.phoneList}>
                    <a href="/jobs">Jobs</a>
                    <a href="/career">Join the CogentHub Family</a>
                  </div>
                )}
                <a href="/about-us" onClick={onDiscover}>
                  Discover
                </a>
                <a href="/contact-us">Contact</a>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      )}
      {!phoneView && (
        <div>
          <div className={classes.leftAlign}>
            <a href="/" className={classes.logoLink}>
              <img src="/cogentLogo.svg" className={classes.logo} />
            </a>
            <a
              href="#insights"
              onClick={onInsights}
              className={classes.headerItem}
            >
              Insights
            </a>
            <a
              href="#services"
              onClick={onServices}
              className={classes.headerItem}
            >
              Services
            </a>
            <a
              href="#industries"
              onClick={onIndustries}
              className={classes.headerItem}
            >
              Industries
            </a>
            {/* <a href="#about" onClick={onAchievements}>
      Achievements
    </a> */}
            <a href="#career" onClick={onCareer} className={classes.headerItem}>
              Career
            </a>
            <a href="/about-us" className={classes.headerItem}>
              Discover
            </a>
            <a href="/contact-us" className={classes.headerItem}>
              Contact
            </a>
            {isAdmin && (
              <a href="#" onClick={onAdd} className={classes.headerItem}>
                Add
              </a>
            )}
            <div className={classes.rightAlign}>
              {!isAdmin && (
                <div className={classes.buttonContainer}>
                  <a href="/login" className={classes.button}>
                    Sign In
                  </a>
                </div>
              )}
              {isAdmin && (
                <a
                  href="#"
                  className={classes.button}
                  onClick={() => {
                    localStorage.setItem('token', '')
                    window.location.href = '/'
                  }}
                >
                  Log Out
                </a>
              )}
              {/* <p>
        <SearchIcon />
      </p> */}
            </div>
          </div>
          <div className={classes.dropdown}>
            {insights === true && !phoneView && <Test boolean={true} />}
            {insights === false && !phoneView && <Test boolean={false} />}
            {services === true && !phoneView && <Services boolean={true} />}
            {services === false && !phoneView && <Services boolean={false} />}
            {industries === true && !phoneView && <Industries boolean={true} />}
            {industries === false && !phoneView && (
              <Industries boolean={false} />
            )}
            {achievements === true && !phoneView && (
              <Achievements boolean={true} />
            )}
            {achievements === false && !phoneView && (
              <Achievements boolean={false} />
            )}
            {career === true && !phoneView && <Career boolean={true} />}
            {career === false && !phoneView && <Career boolean={false} />}
            {discover === true && !phoneView && <Discover boolean={true} />}
            {discover === false && !phoneView && <Discover boolean={false} />}
            {add === true && !phoneView && <Add boolean={true} />}
            {add === false && !phoneView && <Add boolean={false} />}
          </div>
        </div>
      )}
    </>
  )
}

export default HeaderTemp

import { useState, useEffect, Suspense } from 'react'
import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { ThemeProvider } from '@material-ui/core/styles'
// import HomePage from './components/pages/HomePage'
const HomePage = React.lazy(() => import('./components/pages/HomePage'))
import AdvertLandingPage from './components/pages/AdvertLandingPage'
import CareerPage from './components/pages/CareerPage'
import JobsPage from './components/pages/JobsPage'
import IndustriesPage from './components/pages/IndustriesPage'
import ServicesPage from './components/pages/ServicePage'
import Discover from './components/pages/Discover'
import Login from './components/Login/Login'
import PrivateRoute from './components/PrivateRoutes/PrivateRoute'
import AddBlogPage from './components/pages/AddBlogPage'
import AddJobsPage from './components/pages/AddJobsPage'
import BlogsPage from './components/pages/BlogsPage'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import ContactUsPage from './components/pages/ContactUsPage'
import ThankYouPage from './components/pages/ThankYouPage'
import BlogFind from './components/Blogs/BlogFind'
import BlogEdit from './components/Blogs/BlogEdit'
import Contacts from './components/Admin/Contacts'
import PPCcontacts from './components/Admin/PPCcontacts'
import theme from './Theme/theme'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import content from './ContentJson/content.json'
import logo from './logo.svg'
import './App.css'
import './fonts/GraphikLight.otf'
import './fonts/GraphikMedium.otf'
import './fonts/GraphikBold.otf'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginRight: 'auto',
  },
}))

function App() {
  const [service, setService] = useState('')
  const [isHeader, setIsHeader] = useState(false)
  const [currentBlog, setCurrentBlog] = useState({})
  const classes = useStyles()
  return (
    <div style={{ fontFamily: 'Light', overflowX: 'hidden', width: 'auto' }}>
      <CookieConsent
        location="bottom"
        buttonText="I Agree"
        cookieName="cogentCookie"
        style={{ background: '#2B373B', flex: 'none' }}
        contentStyle={{ flex: 'none' }}
        buttonStyle={{
          color: '#4e503b',
          fontSize: '16px',
          borderRadius: '10px',
        }}
        buttonWrapperClasses={classes.buttonContainer}
        expires={150}
      >
        This website uses cookies to ensure you get the best experience on our
        website.{' '}
      </CookieConsent>
      <Router>
        <ThemeProvider theme={theme}>
          <Route path="/" exact>
            <Suspense fallback={<div>Loading....</div>}>
              <HomePage logo={logo} />
            </Suspense>
          </Route>
          <Route path="/career" exact>
            <CareerPage setIsHeader={setIsHeader} isHeader={isHeader} />
          </Route>
          <Route path="/jobs" exact>
            <JobsPage setIsHeader={setIsHeader} isHeader={isHeader} />
          </Route>
          <Route path="/industries/retail" exact>
            <IndustriesPage
              bannerTitle={content.RetailTitle}
              bannerSubText={content.RetailSubTitle}
              retailDataOne={content.RetailDataOne}
              retailDataTwo={content.RetailDataTwo}
              retailDataThree={content.RetailDataThree}
              retailCaptionOne={content.RetailCaptionOne}
              retailCaptionTwo={content.RetailCaptionTwo}
              retailCaptionThree={content.RetailCaptionThree}
              solutions={content.RetailSolutions}
              solutionsSubText={content.RetailSolutionsSubText}
              img={content.RetailImg}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/industries/ecommerce" exact>
            <IndustriesPage
              bannerTitle={content.EcommerceTitle}
              bannerSubText={content.RetailSubTitle}
              retailDataOne={content.RetailDataOne}
              retailDataTwo={content.RetailDataTwo}
              retailDataThree={content.RetailDataThree}
              retailCaptionOne={content.RetailCaptionOne}
              retailCaptionTwo={content.RetailCaptionTwo}
              retailCaptionThree={content.RetailCaptionThree}
              solutions={content.EcommerceSolutions}
              solutionsSubText={content.EcommerceSolutionsSubText}
              img={content.EcommerceImg}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/industries/food-tech" exact>
            <IndustriesPage
              bannerTitle={content.FoodTechTitle}
              bannerSubText={content.RetailSubTitle}
              retailDataOne={content.RetailDataOne}
              retailDataTwo={content.RetailDataTwo}
              retailDataThree={content.RetailDataThree}
              retailCaptionOne={content.RetailCaptionOne}
              retailCaptionTwo={content.RetailCaptionTwo}
              retailCaptionThree={content.RetailCaptionThree}
              solutions={content.FoodTechSolutions}
              solutionsSubText={content.FoodTechSolutionsSubText}
              img={content.FoodTechImg}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/industries/travel-tech" exact>
            <IndustriesPage
              bannerTitle={content.TravelTechTitle}
              bannerSubText={content.RetailSubTitle}
              retailDataOne={content.RetailDataOne}
              retailDataTwo={content.RetailDataTwo}
              retailDataThree={content.RetailDataThree}
              retailCaptionOne={content.RetailCaptionOne}
              retailCaptionTwo={content.RetailCaptionTwo}
              retailCaptionThree={content.RetailCaptionThree}
              solutions={content.TravelTechSolutions}
              solutionsSubText={content.TravelTechSolutionsSubText}
              img={content.TravelTechImg}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/industries/ed-tech" exact>
            <IndustriesPage
              bannerTitle={content.EdTechTitle}
              bannerSubText={content.RetailSubTitle}
              retailDataOne={content.RetailDataOne}
              retailDataTwo={content.RetailDataTwo}
              retailDataThree={content.RetailDataThree}
              retailCaptionOne={content.RetailCaptionOne}
              retailCaptionTwo={content.RetailCaptionTwo}
              retailCaptionThree={content.RetailCaptionThree}
              solutions={content.EdTechSolutions}
              solutionsSubText={content.EdTechSolutionsSubText}
              img={content.EdTechImg}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/services/integrated-bpo" exact>
            <ServicesPage
              service={content.IntegratedBpo}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/services/knowledge-services" exact>
            <ServicesPage
              service={content.KnowledgeServices}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/services/back-office-management" exact>
            <ServicesPage
              service={content.BackOfficeManagement}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/services/front-office-management" exact>
            <ServicesPage
              service={content.FrontOfficeManagement}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/services/bureau-services" exact>
            <ServicesPage
              service={content.BureauServices}
              setIsHeader={setIsHeader}
              isHeader={isHeader}
            />
          </Route>
          <Route path="/about-us" exact>
            <Discover setIsHeader={setIsHeader} isHeader={isHeader} />
          </Route>
          <Route path="/login" exact>
            <Login setIsHeader={setIsHeader} isHeader={isHeader} />
          </Route>
          <Route path="/add-blog" exact>
            <AddBlogPage setIsHeader={setIsHeader} isHeader={isHeader} />
          </Route>
          <Route path="/add-jobs" exact>
            <AddJobsPage setIsHeader={setIsHeader} isHeader={isHeader} />
          </Route>
          {/* <PrivateRoute
            path="/add-blog"
            exact
            render={(props) => {
              ;<AddBlogPage
                {...props}
                setIsHeader={setIsHeader}
                isHeader={isHeader}
              />
            }}
          /> */}
          <Route path="/blogs" exact>
            <BlogsPage setIsHeader={setIsHeader} isHeader={isHeader} />
          </Route>
          {/* <PrivateRoute
            path="/add-jobs"
            exact
            render={() => {
              ;<AddJobsPage setIsHeader={setIsHeader} isHeader={isHeader} />
            }}
          /> */}
          <Route path="/contact-us" exact>
            <ContactUsPage setIsHeader={setIsHeader} isHeader={isHeader} />
          </Route>
          <Route path="/privacy-policy" exact>
            <PrivacyPolicy />
          </Route>
          <Route path="/blog/:name" exact>
            <BlogFind setCurrentBlog={setCurrentBlog} />
          </Route>
          <Route path="/blogs/edit-blog" exact>
            <BlogEdit />
          </Route>
          <Route path="/bpo-services" exact>
            <AdvertLandingPage />
          </Route>
          <Route path="/admin-contacts" exact>
            <Contacts />
          </Route>
          <Route path="/contact-us/thank-you" exact>
            <ThankYouPage />
          </Route>
          <Route path="/admin-ppc-contacts" exact>
            <PPCcontacts />
          </Route>
        </ThemeProvider>
      </Router>
    </div>
  )
}

export default App

import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import content from '../../ContentJson/content.json'
import { makeStyles } from '@material-ui/styles'
import 'bootstrap/dist/css/bootstrap.css'
import Form from 'react-bootstrap/Form'
import Banner from './advertBanner.png'
import phoneBanner from './phoneBanner.svg'
import phoneBannerM from './phoneBannerM.png'
import phoneBannerL from './phoneBannerL.png'
import desktopBanner from './1900W.png'
import useWindowDimensions from '../../useWindowDimensions'
import Spinner from 'react-bootstrap/Spinner'
import Toast from 'react-bootstrap/Toast'
import ToastHeader from 'react-bootstrap/ToastHeader'
import ToastBody from 'react-bootstrap/ToastBody'
import ToastContainer from 'react-bootstrap/ToastContainer'
const axios = require('axios')

const useStyles = makeStyles((theme) => ({
  container: {
    height: '850px',
    [theme.breakpoints.up('tablet')]: {
      height: '1000px',
    },
    [theme.breakpoints.up('smallLaptop')]: {
      height: '850px',
    },
    [theme.breakpoints.up('middleLaptop')]: {
      height: '850px',
    },
    [theme.breakpoints.up('macbook')]: {
      height: '900px',
    },
    [theme.breakpoints.up('laptop')]: {
      height: '850px',
    },
    [theme.breakpoints.up('desktop')]: {
      height: '850px',
      backgroundImage: `url(${desktopBanner})`,
    },
    [theme.breakpoints.up('fourK')]: {
      height: '850px',
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/2450W.png)`,
    },
    backgroundImage: `url(${Banner})`,
  },
  title: {
    [theme.breakpoints.up('tablet')]: {
      position: 'relative',
      right: '7rem',
      marginTop: '10rem',
      marginLeft: '1rem',
      '& h1': {
        color: 'white',
        fontFamily: theme.fontFamily.heading,
        fontSize: '48px',
        lineHeight: '62.4px',
      },
      '& h4': {
        color: 'white',
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '15px 30px',
        fontFamily: theme.fontFamily.text,
        fontSize: '18px',
      },
    },
    [theme.breakpoints.up('smallLaptop')]: {
      position: 'relative',
      right: '7rem',
      marginTop: '10rem',
      marginLeft: '1rem',
      '& h1': {
        color: 'white',
        fontFamily: theme.fontFamily.heading,
        fontSize: '48px',
        lineHeight: '62.4px',
      },
      '& h4': {
        color: 'white',
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '15px 30px',
        fontFamily: theme.fontFamily.text,
        fontSize: '18px',
      },
    },
    [theme.breakpoints.up('middleLaptop')]: {
      position: 'static',
      right: '4rem',
      marginTop: '20rem',
      '& h1': {
        color: 'white',
        fontFamily: theme.fontFamily.heading,
        fontSize: '48px',
        lineHeight: '62.4px',
      },
      '& h4': {
        color: 'white',
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '15px 30px',
        fontFamily: theme.fontFamily.text,
        fontSize: '18px',
      },
    },
    [theme.breakpoints.up('macbook')]: {
      marginTop: '24rem',
      marginLeft: '10rem',
      '& h1': {
        color: 'white',
        fontFamily: theme.fontFamily.heading,
        fontSize: '48px',
        lineHeight: '62.4px',
      },
      '& h4': {
        color: 'white',
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '15px 30px',
        fontFamily: theme.fontFamily.text,
        fontSize: '18px',
      },
    },
    [theme.breakpoints.up('laptop')]: {
      marginTop: '24rem',
      marginLeft: '10rem',
      '& h1': {
        color: 'white',
        fontFamily: theme.fontFamily.heading,
        fontSize: '48px',
        lineHeight: '62.4px',
      },
      '& h4': {
        color: 'white',
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '15px 30px',
        fontFamily: theme.fontFamily.text,
        fontSize: '18px',
      },
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: '24rem',
      marginLeft: '10rem',
      '& h1': {
        color: 'white',
        fontFamily: theme.fontFamily.heading,
        fontSize: '48px',
        lineHeight: '62.4px',
      },
      '& h4': {
        color: 'white',
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '15px 30px',
        fontFamily: theme.fontFamily.text,
        fontSize: '18px',
      },
    },
  },
  formContainer: {
    [theme.breakpoints.up('tablet')]: {
      position: 'relative',
      right: '6rem',
      marginTop: '11rem',
      marginLeft: '1rem',
      border: '2px solid white',
      borderRadius: '20px',
      padding: '2rem',
      backgroundColor: 'rgba(230,173,0,0.3)',
      width: '250px',
      color: 'white',
      fontFamily: theme.fontFamily.text,
      '& h3': {
        margin: 'auto',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '12px 30px',
        fontFamily: theme.fontFamily.text,
      },
    },
    [theme.breakpoints.up('smallLaptop')]: {
      position: 'relative',
      right: '6rem',
      marginTop: '11rem',
      marginLeft: '3rem',
      border: '2px solid white',
      borderRadius: '20px',
      padding: '2rem',
      backgroundColor: 'rgba(230,173,0,0.3)',
      width: '100%',
      color: 'white',
      fontFamily: theme.fontFamily.text,
      '& h3': {
        margin: 'auto',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '12px 30px',
        fontFamily: theme.fontFamily.text,
      },
    },
    [theme.breakpoints.up('middleLaptop')]: {
      position: 'relative',
      right: '3rem',
      marginTop: '15rem',
      marginLeft: '3rem',
      border: '2px solid white',
      borderRadius: '20px',
      padding: '2rem',
      backgroundColor: 'rgba(230,173,0,0.3)',
      width: '90%',
      color: 'white',
      fontFamily: theme.fontFamily.text,
      '& h3': {
        margin: 'auto',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '12px 30px',
        fontFamily: theme.fontFamily.text,
      },
    },
    [theme.breakpoints.up('macbook')]: {
      position: 'static',
      marginTop: '19rem',
      marginLeft: '3rem',
      border: '2px solid white',
      borderRadius: '20px',
      padding: '2rem',
      backgroundColor: 'rgba(230,173,0,0.3)',
      width: '70%',
      color: 'white',
      fontFamily: theme.fontFamily.text,
      '& h3': {
        margin: 'auto',
        fontFamily: theme.fontFamily.text,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '12px 30px',
        fontFamily: theme.fontFamily.text,
      },
    },
    [theme.breakpoints.up('laptop')]: {
      position: 'static',
      marginTop: '13rem',
      marginLeft: '3rem',
      border: '2px solid white',
      borderRadius: '20px',
      padding: '2rem',
      backgroundColor: 'rgba(230,173,0,0.3)',
      width: '70%',
      color: 'white',
      '& h3': {
        margin: 'auto',
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '12px 30px',
      },
    },
    [theme.breakpoints.up('desktop')]: {
      position: 'static',
      marginTop: '13rem',
      marginLeft: '3rem',
      border: '2px solid white',
      borderRadius: '20px',
      padding: '2rem',
      backgroundColor: 'rgba(230,173,0,0.3)',
      width: '70%',
      color: 'white',
      '& h3': {
        margin: 'auto',
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: theme.background.yellow,
        borderRadius: '20px',
        padding: '12px 30px',
      },
    },
  },
  connect: {
    textAlign: 'center',
    marginBottom: '1rem',
    '& h3': {
      fontFamily: theme.fontFamily.heading,
    },
  },
  phoneContainer: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('mobileM')]: {
      height: '640px',
      backgroundImage: `url(${phoneBannerM})`,
    },
    [theme.breakpoints.up('mobile395')]: {
      height: '640px',
      backgroundImage: `url(${phoneBannerL})`,
    },
    [theme.breakpoints.up('mobile')]: {
      height: '640px',
      backgroundImage: `url(${phoneBannerL})`,
    },
    [theme.breakpoints.up('tablet')]: {
      height: '640px',
      backgroundImage: `url(${Banner})`,
    },
    padding: '20px',
    backgroundImage: `url(${phoneBanner})`,
    backgroundRepeat: 'no-repeat',
  },
  phoneTitle: {
    color: 'white',
    textAlign: 'center',
    padding: '8rem 1rem 0 1rem',
    [theme.breakpoints.up('mobileM')]: {
      padding: '10rem 1rem 0 1rem',
    },
    [theme.breakpoints.up('mobile')]: {
      padding: '12rem 1rem 0 1rem',
    },
    [theme.breakpoints.up('tablet')]: {
      padding: '12rem 1rem 0 1rem',
      marginTop: '7rem',
    },
    '& h1': {
      fontSize: '28px',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.heading,
    },
    '& h5': {
      marginBottom: '2rem',
      fontWeight: 500,
      fontSize: '18px',
      fontFamily: theme.fontFamily.text,
    },
  },
  phoneFormContainer: {
    marginTop: '7rem',
    border: '2px solid white',
    borderRadius: '20px',
    padding: '1rem',
    backgroundColor: '#EEC64D',
    width: '100%',
    color: 'white',
    '& h3': {
      margin: 'auto',
    },
    '& a': {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#E6AD00',
      borderRadius: '20px',
      padding: '12px 50px',
    },
    [theme.breakpoints.up('mobileM')]: {},
    [theme.breakpoints.up('mobileMS')]: {},
    [theme.breakpoints.up('mobile')]: {},
    [theme.breakpoints.up('tablet')]: {
      width: '70%',
      margin: '7rem auto auto auto',
    },
    [theme.breakpoints.up('smallLaptop')]: {
      width: '100%',
    },
    [theme.breakpoints.up('middleLaptop')]: {
      width: '100%',
    },
    [theme.breakpoints.up('macbook')]: {
      width: '100%',
    },
    [theme.breakpoints.up('laptop')]: {
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      width: '100%',
    },
  },
  phoneConnect: {
    textAlign: 'center',
    fontFamily: theme.fontFamily.heading,
    fontSize: '20px',
    fontWeight: 700,
  },
  loading: {
    marginTop: '10rem',
    marginLeft: '10rem',
    color: 'black',
    [theme.breakpoints.up('laptop')]: {
      marginLeft: '45rem',
      marginTop: '20rem',
      color: 'white',
    },
  },
  toast: {
    [theme.breakpoints.up('laptop')]: {
      position: 'relative',
      zIndex: '9990',
    },
  },
  contactUs: {
    '& a': {
      textDecoration: 'none',
      color: 'white',
      fontSize: '16px',
      fontFamily: theme.fontFamily.text,
    },
    width: '250px',
    height: '40px',
    [theme.breakpoints.up('mobileM')]: {
      width: '300px',
      height: '40px',
    },
    [theme.breakpoints.up('mobileMS')]: {
      width: '300px',
      height: '40px',
    },
    [theme.breakpoints.up('mobile')]: {
      width: '350px',
      height: '40px',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '350px',
      height: '40px',
      margin: 'auto',
    },
    paddingTop: '7px',
    backgroundColor: theme.background.yellow,
    borderRadius: '20px',
  },
  formLabel: {
    fontFamily: theme.fontFamily.text,
    fontSize: '14px',
    fontWeight: 500,
  },
  placeholder: {
    fontFamily: theme.fontFamily.text,
    fontSize: '11px',
    fontWeight: 400,
  },
  submit: {
    fontFamily: theme.fontFamily.text,
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  nasscom: {
    [theme.breakpoints.up('mobileS')]: {
      marginTop: '1rem',
      '& img': {
        position: 'relative',
        right: '12px',
      },
    },
    [theme.breakpoints.up('mobileMS')]: {
      marginTop: '2rem',
      '& img': {
        position: 'relative',
        left: '6px',
      },
    },
    [theme.breakpoints.up('mobileM')]: {
      marginTop: '2rem',
      '& img': {
        position: 'relative',
        left: '0px',
        right: '0px',
      },
    },
    [theme.breakpoints.up('mobile')]: {
      marginTop: '2rem',
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: '2rem',
    },
    [theme.breakpoints.up('smallLaptop')]: {
      marginTop: '2rem',
    },
    [theme.breakpoints.up('middleLaptop')]: {
      marginTop: '2rem',
    },
    [theme.breakpoints.up('macbook')]: {
      marginTop: '2rem',
    },
    [theme.breakpoints.up('laptop')]: {
      marginTop: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {},
  },
}))

export default function AdvertBanner({ title, subTitle }) {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [preNumber, setPreNumber] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const onName = (e) => {
    setName(e.target.value)
  }

  const onNumber = (e) => {
    setNumber(e.target.value)
  }

  const onEmail = (e) => {
    setEmail(e.target.value)
  }

  const onCompany = (e) => {
    setCompany(e.target.value)
  }

  const onPreNumber = (e) => {
    setPreNumber(e.target.value)
  }

  const onSubmit = () => {
    setLoading(true)
    let phoneNumber = preNumber + number
    console.log('number', phoneNumber)
    axios
      .post('https://cogenthub-api.herokuapp.com/contact/contactppc', {
        name: name,
        number: phoneNumber,
        email: email,
        company: company,
      })
      .then(function (response) {
        setLoading(false)
        setShowSuccess(true)
        window.location.href = '/contact-us/thank-you'
        console.log('Storage', response)
      })
      .catch((error) => {
        setLoading(false)
        setShowError(true)
        console.log('Storage', error.response)
      })
  }

  useEffect(() => {
    if (width < 770) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
    console.log('phoneView', phoneView)
    console.log('width', width)
  }, [width])

  return (
    <>
      {!phoneView && (
        <div className={classes.container}>
          {isLoading && (
            <Spinner
              animation="border"
              role="status"
              className={classes.loading}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {!isLoading && (
            <Row>
              <div
                aria-live="polite"
                aria-atomic="true"
                className={classes.toast}
              >
                <ToastContainer className="p-3" position="top-end">
                  <Toast
                    bg="success"
                    onClose={() => setShowSuccess(false)}
                    show={showSuccess}
                    delay={3000}
                    autohide
                  >
                    <Toast.Header closeButton={true}>
                      <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                      />
                      <strong className="me-auto">CogentHub</strong>
                    </Toast.Header>
                    <Toast.Body>Thank you for reaching out to us!</Toast.Body>
                  </Toast>
                </ToastContainer>
                <ToastContainer className="p-3" position="top-end">
                  <Toast
                    bg="danger"
                    onClose={() => setShowError(false)}
                    show={showError}
                    delay={3000}
                    autohide
                  >
                    <Toast.Header closeButton={true}>
                      <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                      />
                      <strong className="me-auto">CogentHub</strong>
                    </Toast.Header>
                    <Toast.Body>
                      Please fill in the fields with the correct information!
                    </Toast.Body>
                  </Toast>
                </ToastContainer>
              </div>
              <Col md={true} className={classes.title}>
                <h1>
                  Make Your Customers Feel Special with Personalised and
                  Tailored Customer Support
                </h1>
                <h4>
                  We help you gain a competitive advantage by combining data
                  analytics and agent-assisted automated technology.{' '}
                </h4>
                <a href="/contact-us">Contact Us</a>
                <div className={classes.nasscom}>
                  <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Frame+526.png" />
                </div>
              </Col>
              <Col md={true}>
                <div className={classes.formContainer}>
                  <div className={classes.connect}>
                    <h3>Connect With Us</h3>
                  </div>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your full name"
                        onChange={onName}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Phone Number</Form.Label>
                      <Row>
                        <Col xs={3}>
                          <Form.Control
                            type="text"
                            placeholder="+44"
                            onChange={onPreNumber}
                          />
                        </Col>
                        <Col xs={9}>
                          <Form.Control
                            type="text"
                            placeholder="Enter phone number"
                            onChange={onNumber}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Business Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your business email"
                        onChange={onEmail}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Company</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your company name"
                        onChange={onCompany}
                      />
                    </Form.Group>
                    <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                      <a href="#" onClick={onSubmit}>
                        Submit
                      </a>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
      {phoneView && (
        <div>
          {isLoading && (
            <Spinner
              animation="border"
              role="status"
              className={classes.loading}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          {!isLoading && (
            <div>
              <div className={classes.phoneContainer}>
                <div className={classes.phoneTitle}>
                  <h1>
                    Make Your Customers Feel Special with Personalised and
                    Tailored Customer Support
                  </h1>
                  <h5>
                    We help you gain a competitive advantage by combining data
                    analytics and agent-assisted automated technology.{' '}
                  </h5>
                  <div className={classes.contactUs}>
                    <a href="/contact-us">Contact Us</a>
                  </div>
                  <div className={classes.nasscom}>
                    <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/phoneNasscom.png" />
                  </div>
                </div>
              </div>
              <div className={classes.phoneFormContainer}>
                <div
                  aria-live="polite"
                  aria-atomic="true"
                  className={classes.toast}
                >
                  <ToastContainer className="p-3" position="top-end">
                    <Toast
                      bg="success"
                      onClose={() => setShowSuccess(false)}
                      show={showSuccess}
                      delay={3000}
                      autohide
                    >
                      <Toast.Header closeButton={true}>
                        <img
                          src="holder.js/20x20?text=%20"
                          className="rounded me-2"
                          alt=""
                        />
                        <strong className="me-auto">CogentHub</strong>
                      </Toast.Header>
                      <Toast.Body>Thank you for reaching out to us!</Toast.Body>
                    </Toast>
                  </ToastContainer>
                  <ToastContainer className="p-3" position="top-end">
                    <Toast
                      bg="danger"
                      onClose={() => setShowError(false)}
                      show={showError}
                      delay={3000}
                      autohide
                    >
                      <Toast.Header closeButton={true}>
                        <img
                          src="holder.js/20x20?text=%20"
                          className="rounded me-2"
                          alt=""
                        />
                        <strong className="me-auto">CogentHub</strong>
                      </Toast.Header>
                      <Toast.Body>
                        Please fill in the fields with the correct information!
                      </Toast.Body>
                    </Toast>
                  </ToastContainer>
                </div>
                <div className={classes.phoneConnect}>
                  <h3>Connect With Us</h3>
                </div>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.formLabel}>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your full name"
                      onChange={onName}
                      className={classes.placeholder}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className={classes.formLabel}>
                      Phone Number
                    </Form.Label>
                    <Row>
                      <Col xs={3}>
                        <Form.Control
                          type="text"
                          placeholder="+44"
                          onChange={onPreNumber}
                          className={classes.placeholder}
                        />
                      </Col>
                      <Col xs={9}>
                        <Form.Control
                          type="text"
                          placeholder="Enter phone number"
                          onChange={onNumber}
                          className={classes.placeholder}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.formLabel}>
                      Business Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your business email"
                      onChange={onEmail}
                      className={classes.placeholder}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className={classes.formLabel}>
                      Company
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your company name"
                      onChange={onCompany}
                      className={classes.placeholder}
                    />
                  </Form.Group>
                  <div className={classes.submit}>
                    <a href="#" onClick={onSubmit}>
                      Submit
                    </a>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  light: {
    text: '#000000',
  },
  background: {
    white: '#F2EFC4',
    green: '#BED730',
    yellow: '#E6AD00',
  },
  text: {
    primary: '#188C4F',
    secondary: '#B8B8B8',
    yellow: '#FFCB05',
    darkGreen: '#292929',
    heading: '#064621',
    subText: '#575757',
  },
  fontSize: {
    bannerTitle: '50px',
    h1: '24px',
    h2: '19px',
    h3: '18px',
    h4: '17px',
    p: '16px',
    footerLink: '14px',
    heading: '48px',
    text: '16px',
  },
  fontWeight: {
    light: '300',
    regular: '400',
    medium: '500',
    bold: '700',
    black: '900',
    blaccker: '1000',
  },
  breakpoints: {
    values: {
      mobileS: 320,
      mobileM: 375,
      mobileMS: 360,
      mobile395: 395,
      mobile: 400,
      tablet: 768,
      smallLaptop: 1024,
      middleLaptop: 1366,
      laptop: 1536,
      macbook: 1440,
      desktop: 1700,
      fourK: 2560,
    },
  },
  fontFamily: {
    heading: 'Playfair Display',
    text: 'DM Sans',
  },
})

export default theme

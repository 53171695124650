import { React, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import content from '../../ContentJson/content.json'
import Container from 'react-bootstrap/Container'
import AOS from 'aos'
import CardGroup from 'react-bootstrap/CardGroup'
import Card from 'react-bootstrap/Card'
import useWindowDimensions from '../../useWindowDimensions'

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('laptop')]: {
      margin: '0',
    },
    [theme.breakpoints.down('md')]: {
      margin: '1rem',
    },
    '& h4': {
      fontFamily: theme.fontFamily.text,
    },
  },
  awards: {
    width: '80%',
    margin: 'auto',
  },
  subContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    textAlign: 'center',
    margin: '2rem',
    '& h1': {
      borderBottom: '2px solid #FFCB05',
      fontFamily: theme.fontFamily.heading,
      fontSize: theme.fontSize.heading,
      color: theme.text.heading,
      fontWeight: 700,
      color: '#064621',
      margin: 'auto',
    },
  },
  card: {
    fontFamily: theme.fontFamily.text,
    [theme.breakpoints.up('laptop')]: {
      margin: '2rem',
    },
    [theme.breakpoints.down('tablet')]: {
      margin: '1rem',
    },
  },
  subText: {
    textAlign: 'center',
    color: '#575757',
    fontFamily: theme.fontFamily.text,
    fontSize: theme.fontSize.text,
  },
}))

function Awards() {
  const classes = useStyles()
  const [phoneView, setPhoneView] = useState(false)
  const { height, width } = useWindowDimensions()

  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
    if (width <= 500) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  }, [])

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>
          <h1>Awards and Certifications</h1>
        </div>
        <div
          style={{ textAlign: 'center', color: '#575757' }}
          className={classes.subText}
        >
          <h4>Industry Certifications and Accreditations</h4>
        </div>
        <CardGroup className={classes.awards}>
          <Card className={classes.card}>
            <Card.Img
              variant="top"
              src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/award1-webp.webp"
            />
            <Card.Body>
              <Card.Title>ISO/IEC 27701 CERTIFIED</Card.Title>
            </Card.Body>
          </Card>
          <Card className={classes.card}>
            <Card.Img variant="top" src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/award2-webp.webp" />
            <Card.Body>
              <Card.Title>ISO 9001 CERTIFIED</Card.Title>
            </Card.Body>
          </Card>
          <Card className={classes.card}>
            <Card.Img
              variant="top"
              src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/award3-webp.webp"
            />
            <Card.Body>
              <Card.Title>ISO/IEC 27001 CERTIFIED</Card.Title>
            </Card.Body>
          </Card>

          {/* <Card style={{ margin: '5px' }}>
            <Card.Img
              variant="top"
              src="https://www.cogenthub.com/img/award1.jpg"
            />
            <Card.Body>
              <Card.Title>CERTIFICATION OF HONOUR</Card.Title>
            </Card.Body>
          </Card> */}
        </CardGroup>
      </div>
    </>
  )
}

export default Awards

import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Carousel from 'react-bootstrap/Carousel'
import content from '../../ContentJson/content.json'
import { makeStyles } from '@material-ui/styles'
import 'bootstrap/dist/css/bootstrap.css'
import Banner from './bannerImage.png'
import useWindowDimensions from '../../useWindowDimensions'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '6rem 4rem',
    [theme.breakpoints.up('mobileS')]: {
      padding: '6rem 1rem',
    },
    [theme.breakpoints.up('mobileM')]: {
      padding: '6rem 4rem',
    },
    textAlign: 'center',
    backgroundColor: '#F5DE99',
  },
  content: {
    '& a': {
      borderRadius: '20px',
      width: '50%',
      margin: 'auto',
      color: 'black',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.text,
      backgroundColor: '#ffc107',
      padding: '10px 50px',
      textDecoration: 'none',
    },
    '& h1': {
      fontFamily: theme.fontFamily.heading,
      fontWeight: 'bold',
      color: '#064621',
    },
    '& h4': {
      fontSize: '24px',
      fontFamily: theme.fontFamily.text,
      fontWeight: 'bold',
      marginBottom: '1rem',
      color: '#575757',
    },
  },
}))

export default function ServiceConsultation({ service, isHeader }) {
  const classes = useStyles()
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [image, setImage] = useState('')
  const { height, width } = useWindowDimensions()

  useEffect(() => {}, [])

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h1>Get a free consultation and analysis report</h1>
        <h4>Exclusively Customized for Your Company</h4>
        <a href="/contact-us">Let’s Connect</a>
      </div>
    </div>
  )
}

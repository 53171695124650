import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import useWindowDimensions from '../../useWindowDimensions'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: '3rem',
    marginBottom: '4rem',
  },
  title: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '1rem',
    '& h1': {
      width: '90%',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.heading,
      color: '#064621',
      fontSize: '48px',
    },
  },
  phoneTitle: {
    '& h1': {
      width: '100%',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.heading,
      color: '#064621',
      fontSize: '48px',
    },
  },
  img: {
    width: '100%',
    '& img': {
      width: '90%',
    },
  },
  subText: {
    fontSize: '1.5rem',
    fontFamily: theme.fontFamily.text,
    fontWeight: 'bold',
    color: '#575757',
  },
  phoneImg: {
    width: '100%',
    marginBottom: '2rem',
  },
}))

const AdvertGlobalReach = () => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 500) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  })

  return (
    <div className={classes.container}>
      <div className={classes.phoneTitle}>
        <h1>The Global Team Of CogentHub</h1>
      </div>
      <div className={classes.subText}>
        <p>
          We Serve 40+ clients in UK, Canada, US, Western Europe, <br />{' '}
          Middle-East Asia, Australia and New Zealand.
        </p>
      </div>
      {!phoneView && (
        <div className={classes.img}>
          <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Frame+530.png" />
        </div>
      )}
      {phoneView && (
        <div>
          <img
            src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+section-the+global+team+of+cogenthub+(with+background).png"
            className={classes.phoneImg}
          />
          <div style={{ textAlign: 'left', marginBottom: '2rem' }}>
            <h1
              style={{
                fontSize: '78px',
                color: '#0C8B41',
                fontWeight: 'bold',
                fontFamily: 'DM Sans',
              }}
            >
              500+
            </h1>
            <h1 style={{ fontFamily: 'DM Sans' }}>
              CogentHub Employees Worldwide
            </h1>
          </div>
          <div style={{ textAlign: 'right', marginBottom: '2rem' }}>
            <h1
              style={{
                fontSize: '78px',
                color: '#0C8B41',
                fontWeight: 'bold',
                fontFamily: 'DM Sans',
              }}
            >
              40+
            </h1>
            <h1 style={{ fontFamily: 'DM Sans' }}>Client Services</h1>
          </div>
          <div style={{ textAlign: 'left', marginBottom: '2rem' }}>
            <h1
              style={{
                fontSize: '78px',
                color: '#0C8B41',
                fontWeight: 'bold',
                fontFamily: 'DM Sans',
              }}
            >
              5+
            </h1>
            <h1 style={{ fontFamily: 'DM Sans' }}>
              Countries in which we operate
            </h1>
          </div>
          <div style={{ textAlign: 'right', marginBottom: '2rem' }}>
            <h1
              style={{
                fontSize: '78px',
                color: '#0C8B41',
                fontWeight: 'bold',
                fontFamily: 'DM Sans',
              }}
            >
              5+
            </h1>
            <h1 style={{ fontFamily: 'DM Sans' }}>
              Years of management experience
            </h1>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdvertGlobalReach

import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/styles'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useWindowDimensions from '../../useWindowDimensions'
import Carousel from 'react-bootstrap/Carousel'

const useStyles = makeStyles((theme) => ({
  informationBox: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
    minHeight: '250px',
    textAlign: 'center',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
    padding: '2rem',
    '& h1': {
      fontWeight: theme.fontWeight.bold,
    },
    // marginBottom: '6rem',
    // marginTop: '6rem',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
    padding: '10px 10px 10px 10px',
    borderRadius: '30px',
    backgroundColor: theme.background.yellow,
    '& a': {
      textDecoration: 'none',
      color: 'white',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    width: '40%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: '700px',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '800px',
      height: '600px',
    },
  },
  learnMore: {
    borderRadius: '20px',
    color: 'white',
  },
  title: {
    marginTop: '4rem',
    '& h1': {
      fontFamily: theme.fontFamily.heading,
    },
    '& p': {
      fontFamily: theme.fontFamily.text,
    },
  },
  phoneContainer: {
    textAlign: 'center',
  },
  phoneTitle: {
    padding: '1rem',
    textAlign: 'center',
  },
  titleMain: {
    textAlign: 'center',
    '& h1': {
      color: '#064621',
      fontWeight: 'bold',
    },
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: '3rem',
    '& h4': {
      color: '#575757',
      fontWeight: 'bold',
    },
  },
  point: {
    marginTop: '7px',
    fontSize: '17px',
  },
  pointContainer: {
    margin: '5px',
  },
  phoneImg: {
    width: '370px',
  },
}))

const AdvertCarousel = ({ inView, setInView }) => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 1025) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  }, [width])

  return (
    <div className={classes.container}>
      <Container>
        {!phoneView && (
          <div>
            <div className={classes.titleMain}>
              <h1>Testimonials</h1>
            </div>
            <div className={classes.subTitle}>
              <h4>What our customers say about us</h4>
            </div>
            <Carousel>
              <Carousel.Item>
                <Row>
                  <Col>
                    <img
                      src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+testimonials+section.png"
                      className={classes.img}
                    />
                  </Col>
                  <Col className={classes.title}>
                    <p style={{ fontSize: '19px' }}>
                      Businesses encounter several challenges during their
                      lifecycle. At CogentHub, we help to address these hurdles
                      by contributing unparalleled services in management
                      consulting. We harness the most advanced technology
                      towards delivering a delightful experience for your
                      customers. CogentHub achieves excellence by deploying
                      immaculately planned operations without any compromise.
                      CogentHub presents a wide array of services ranging from
                      front-office management. We continue to services clients
                      in the Retail, Telecom, Finance, Utilities, Healthcare and
                      Travel industries. We have thoughtfully created a
                      passionate group of professionals that prides itself in
                      delivering objectives set out by you. This team subscribes
                      itself to your thought process and goals and endeavours to
                      effectively fuel your business growth.
                    </p>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row>
                  <Col>
                    <img
                      src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+section-why+choose+CH.png"
                      className={classes.img}
                    />
                  </Col>
                  <Col className={classes.title}>
                    <p style={{ fontSize: '19px' }}>
                      Businesses encounter several challenges during their
                      lifecycle. At CogentHub, we help to address these hurdles
                      by contributing unparalleled services in management
                      consulting. We harness the most advanced technology
                      towards delivering a delightful experience for your
                      customers. CogentHub achieves excellence by deploying
                      immaculately planned operations without any compromise.
                      CogentHub presents a wide array of services ranging from
                      front-office management. We continue to services clients
                      in the Retail, Telecom, Finance, Utilities, Healthcare and
                      Travel industries. We have thoughtfully created a
                      passionate group of professionals that prides itself in
                      delivering objectives set out by you. This team subscribes
                      itself to your thought process and goals and endeavours to
                      effectively fuel your business growth.
                    </p>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </div>
        )}
        {phoneView && (
          <div>
            <div className={classes.titleMain}>
              <h1>Testimonials</h1>
            </div>
            <div className={classes.subTitle}>
              <h4>What our customers say about us</h4>
            </div>
            <Carousel>
              <Carousel.Item>
                <Row>
                  <Col>
                    <img
                      src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+testimonials+section.png"
                      className={classes.phoneImg}
                    />
                  </Col>
                  <Col className={classes.phoneTitle}>
                    <p style={{ fontSize: '19px' }}>
                      Businesses encounter several challenges during their
                      lifecycle. At CogentHub, we help to address these hurdles
                      by contributing unparalleled services in management
                      consulting. We harness the most advanced technology
                      towards delivering a delightful experience for your
                      customers. CogentHub achieves excellence by deploying
                      immaculately planned operations without any compromise.
                      CogentHub presents a wide array of services ranging from
                      front-office management. We continue to services clients
                      in the Retail, Telecom, Finance, Utilities, Healthcare and
                      Travel industries. We have thoughtfully created a
                      passionate group of professionals that prides itself in
                      delivering objectives set out by you. This team subscribes
                      itself to your thought process and goals and endeavours to
                      effectively fuel your business growth.
                    </p>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row>
                  <Col>
                    <img
                      src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Image+in+section-why+choose+CH.png"
                      className={classes.phoneImg}
                    />
                  </Col>
                  <Col className={classes.phoneTitle}>
                    <p style={{ fontSize: '19px' }}>
                      Businesses encounter several challenges during their
                      lifecycle. At CogentHub, we help to address these hurdles
                      by contributing unparalleled services in management
                      consulting. We harness the most advanced technology
                      towards delivering a delightful experience for your
                      customers. CogentHub achieves excellence by deploying
                      immaculately planned operations without any compromise.
                      CogentHub presents a wide array of services ranging from
                      front-office management. We continue to services clients
                      in the Retail, Telecom, Finance, Utilities, Healthcare and
                      Travel industries. We have thoughtfully created a
                      passionate group of professionals that prides itself in
                      delivering objectives set out by you. This team subscribes
                      itself to your thought process and goals and endeavours to
                      effectively fuel your business growth.
                    </p>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </div>
        )}
      </Container>
    </div>
  )
}

export default AdvertCarousel

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from 'react-bootstrap/Button'
import AOS from 'aos'
import Card from 'react-bootstrap/Card'
const axios = require('axios')

const useStyles = makeStyles((theme) => ({
  jobs: {
    [theme.breakpoints.up('MobileS')]: {
      padding: '10px',
    },
  },
  jobTitle: {
    fontWeight: 'bold',
    fontSize: '2rem',
    fontFamily: theme.fontFamily.heading,
  },
  text: {
    color: theme.text.yellow,
    '& p': {
      color: 'black',
      fontFamily: theme.fontFamily.text,
    },
  },
}))

const JobCard = ({ job, setLoading, setJobs }) => {
  const classes = useStyles()
  const [isAdmin, setAdmin] = useState(false)

  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
    let token = localStorage.getItem('token')
    axios
      .post('https://cogenthub-api.herokuapp.com/authorize', {
        token: token,
      })
      .then(function (response) {
        setAdmin(true)
        console.log(response)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }, [])

  const handleDelete = (e) => {
    setLoading(true)
    axios
      .post('https://cogenthub-api.herokuapp.com/jobs/deleteJobById', {
        id: job._id,
      })
      .then((response) => {
        console.log('Deleted')
        console.log('blogs', response)
        axios
          .get('https://cogenthub-api.herokuapp.com/jobs/getJobs')
          .then(async (response) => {
            setJobs(response.data.jobs)
            setLoading(false)
          })
          .catch((error) => {
            console.log('There is an error')
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  return (
    <>
      <div className={classes.jobs}>
        <Card style={{ width: '21rem', height: '23rem' }}>
          <Card.Body>
            <Card.Title className={classes.jobTitle}>{job.name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {job.location}
            </Card.Subtitle>
            <Card.Text
              style={{
                fontWeight: 'bold',
                marginBottom: '0',
                marginTop: '1rem',
              }}
              className={classes.text}
            >
              Skills :{' '}
              <p style={{ display: 'inline-block', fontWeight: 'lighter' }}>
                {job.skills},
              </p>
            </Card.Text>
            <Card.Text style={{ fontWeight: 'bold' }} className={classes.text}>
              Years Of Experience :{' '}
              <p style={{ display: 'inline-block', fontWeight: 'lighter' }}>
                {job.experience}
              </p>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Button
              variant="warning"
              style={{ color: 'white' }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfjoni4EQBU-sNaGaM73XHxmUrGTPLvm6aUPz_QEZMWEfUyoA/viewform?usp=pp_url"
            >
              Apply
            </Button>{' '}
            {isAdmin && (
              <Button
                variant="danger"
                style={{ color: 'white' }}
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}
          </Card.Footer>
        </Card>
      </div>
    </>
  )
}

export default JobCard

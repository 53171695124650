import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useWindowDimensions from '../../useWindowDimensions'
import content from '../../ContentJson/content.json'
import { makeStyles } from '@material-ui/styles'
import 'bootstrap/dist/css/bootstrap.css'
import Check from './Icon-Check.png'
import Image from 'react-bootstrap/Image'
import GloballyRenowned from '../GloballyRenowned/GloballyRenowned'
import Button from 'react-bootstrap/Button'
import AOS from 'aos'
import 'aos/dist/aos.css'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    textAlign: 'left',
    backgroundColor: 'white',
    '& h1': {
      fontWeight: 'bold',
      color: theme.text.darkGreen,
      fontFamily: theme.fontFamily.heading,
    },
    padding: '2rem 0 0 10rem',
    [theme.breakpoints.up('desktop')]: {
      padding: '2rem 0 0 22rem',
    },
    [theme.breakpoints.down('md')]: {
      // height: '1500px',
      // padding: '2rem 0 0 4rem',
      padding: '3rem 0 0 0',
      textAlign: 'center',
    },
  },
  img: {
    width: '100px',
    height: '100px',
    marginTop: '1rem',
    // float: 'left',
  },
  text: {
    marginLeft: '2rem',
    fontFamily: theme.fontFamily.text,
  },
  bigImg: {
    width: '100%',
  },
  renowned: {
    marginTop: '4rem',
    backgroundColor: '#FDF7E6',
  },
  button: {
    borderRadius: '40px',
    width: '50%',
    [theme.breakpoints.up('mobileS')]: {
      width: '300px',
      height: '50px',
      paddingTop: '12px',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '50%',
      height: '100%',
      padding: '10px',
    },
    margin: 'auto',
    color: 'black',
    fontWeight: 'bold',
    fontFamily: theme.fontFamily.text,
  },
  phonePointContainer: {
    '& h1': {
      fontWeight: 'bold',
      color: theme.text.heading,
      fontFamily: theme.fontFamily.heading,
      fontSize: '20px',
      paddingTop: '10rem',
    },
    textAlign: 'center',
    marginTop: '1rem',
  },
  pointContainerPhone: {
    background: 'rgb(234,217,171)',
    background:
      'linear-gradient(0deg, rgba(234,217,171,1) 0%, rgba(252,249,241,1) 32%, rgba(255,255,255,1) 48%, rgba(253,252,247,1) 68%, rgba(237,229,183,1) 100%)',
    paddingBottom: '20px',
  },
  imgPhoneContainer: {
    textAlign: 'center',
  },
  imgPhone: {
    position: 'relative',
    top: '7rem',
  },
  tick: {
    width: '50px',
  },
}))

export default function ServiceSectionThree({ service, isHeader }) {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)
  const [tabView, setTabView] = useState(false)

  useEffect(() => {
    if (width < 500) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
    if (width > 500 && width < 770) {
      setTabView(true)
    } else {
      setTabView(false)
    }
  }, [width])

  return (
    <>
      {!phoneView && !tabView && (
        <div>
          {service.Points.map((point) => {
            return (
              <div data-aos="fade-right" data-aos-easing="ease-in-sine">
                <div className={classes.container}>
                  <h1>{point.Title}</h1>
                </div>
                {point.Index % 2 != 0 && (
                  <Container style={{ marginTop: '2rem' }}>
                    <Row>
                      <Col lg={6}>
                        <Container>
                          {point.Keys.map((key) => {
                            return (
                              <Row>
                                <Col lg={2}>
                                  <Image
                                    src={Check}
                                    roundedCircle
                                    className={classes.img}
                                  />
                                </Col>
                                <Col lg={10}>
                                  <p className={classes.text}>{key}</p>
                                </Col>
                              </Row>
                            )
                          })}
                          <Row>
                            <Button
                              variant="warning"
                              className={classes.button}
                              href="/contact-us"
                            >
                              Let's Work Together
                            </Button>{' '}
                          </Row>
                        </Container>
                      </Col>
                      <Col lg={6}>
                        <Image src={point.Image} className={classes.bigImg} />
                      </Col>
                    </Row>
                  </Container>
                )}
                {point.Index % 2 == 0 && (
                  <Container style={{ marginTop: '2rem' }}>
                    <Row>
                      <Col lg={6}>
                        <Image src={point.Image} className={classes.bigImg} />
                      </Col>
                      <Col lg={6}>
                        <Container>
                          {point.Keys.map((key) => {
                            return (
                              <Row>
                                <Col lg={2}>
                                  <Image
                                    src={Check}
                                    roundedCircle
                                    className={classes.img}
                                  />
                                </Col>
                                <Col lg={10}>
                                  <p className={classes.text}>{key}</p>
                                </Col>
                              </Row>
                            )
                          })}
                          <Row>
                            <Button
                              variant="warning"
                              className={classes.button}
                              href="/contact-us"
                            >
                              Let's Work Together
                            </Button>{' '}
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            )
          })}
        </div>
      )}
      {tabView && (
        <>
          <div>
            <div>
              {service.Points.map((point) => {
                return (
                  <div>
                    <div className={classes.imgPhoneContainer}>
                      <Image
                        src={point.ImagePhone}
                        className={classes.imgPhone}
                      />
                    </div>
                    <div className={classes.pointContainerPhone}>
                      <div className={classes.phonePointContainer}>
                        <h1>{point.Title}</h1>
                      </div>
                      <Container style={{ marginTop: '2rem' }}>
                        <Row>
                          <Col lg={12}>
                            <Container>
                              {point.Keys.map((key) => {
                                return (
                                  <Row>
                                    <Col xs={1}>
                                      <Image
                                        src={Check}
                                        roundedCircle
                                        className={classes.tick}
                                      />
                                    </Col>
                                    <Col xs={11}>
                                      <p className={classes.text}>{key}</p>
                                    </Col>
                                  </Row>
                                )
                              })}
                              <Row>
                                <Button
                                  variant="warning"
                                  className={classes.button}
                                  href="/contact-us"
                                >
                                  Let's Work Together
                                </Button>{' '}
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
      {phoneView && (
        <>
          <div>
            <div>
              {service.Points.map((point) => {
                return (
                  <div>
                    <div className={classes.imgPhoneContainer}>
                      <Image
                        src={point.ImagePhone}
                        className={classes.imgPhone}
                      />
                    </div>
                    <div className={classes.pointContainerPhone}>
                      <div className={classes.phonePointContainer}>
                        <h1>{point.Title}</h1>
                      </div>
                      <Container style={{ marginTop: '2rem' }}>
                        <Row>
                          <Col lg={12}>
                            <Container>
                              {point.Keys.map((key) => {
                                return (
                                  <Row>
                                    <Col xs={1}>
                                      <Image
                                        src={Check}
                                        roundedCircle
                                        className={classes.tick}
                                      />
                                    </Col>
                                    <Col xs={11}>
                                      <p className={classes.text}>{key}</p>
                                    </Col>
                                  </Row>
                                )
                              })}
                              <Row>
                                <Button
                                  variant="warning"
                                  className={classes.button}
                                  href="/contact-us"
                                >
                                  Let's Work Together
                                </Button>{' '}
                              </Row>
                            </Container>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </>
  )
}

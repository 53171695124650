import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import content from '../../ContentJson/content.json'
import { makeStyles } from '@material-ui/styles'
import useWindowDimensions from '../../useWindowDimensions'
import 'bootstrap/dist/css/bootstrap.css'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#FDF7E6',
    height: '800px',
    [theme.breakpoints.down('md')]: {
      height: '1500px',
      padding: '1rem',
    },
  },
  expertise: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#FDF7E6',
  },
  img: {
    width: '50%',
    height: '600px',
    '& img': {
      width: '80%',
    },
    marginTop: '0',
    float: 'left',
    [theme.breakpoints.down('md')]: {
      float: 'none',
      width: '100%',
      height: '500px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    // boxShadow: '0 0 0 7px #fff',
    //             0 0 0 8px #ddd,
  },
  title: {
    paddingTop: '4rem',
    '& h1': {
      fontWeight: 'bold',
      color: '#064621',
      fontFamily: theme.fontFamily.heading,
      fontSize: theme.fontSize.heading,
    },
    // marginBottom: '2rem',
  },
  subText: {
    fontSize: '19px',
    fontWeight: 'bold',
    color: '#575757',
    fontFamily: theme.fontFamily.text,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    paddingRight: '3rem',
    '& h4': {
      [theme.breakpoints.down('md')]: {
        marginTop: '0',
        display: 'block',
      },
      marginTop: '11rem',
      fontFamily: theme.fontFamily.text,
      fontSize: theme.fontSize.text,
    },
  },
  expertiseItems: {
    fontWeight: 'bold',
    borderBottom: '2px solid #FFCB05',
  },
  solutions: {
    marginTop: '2rem',
    paddingBottom: '2rem',
    fontFamily: theme.fontFamily.text,
  },
  // Phone Container
  phoneContainer: {
    textAlign: 'center',
  },
  phoneImg: {
    '& img': {
      width: '100%',
    },
  },
  phoneSecondaryText: {
    '& h4': {
      fontSize: '16px',
    },
    textAlign: 'left',
    paddingLeft: '20px',
  },
  phoneSolutions: {
    '& h5': {
      fontSize: '16px',
      fontWeight: 'bold',
      borderBottom: '2px solid #FFCB05',
    },
    textAlign: 'left',
    paddingLeft: '10px',
  },
  phoneSolutionContainer: {
    paddingTop: '1rem',
  },
}))

export default function ServiceSectionTwo({ service, isHeader }) {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)
  const [tabView, setTabView] = useState(false)

  useEffect(() => {
    if (width < 500) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
    if (width > 500 && width < 770) {
      setTabView(true)
    } else {
      setTabView(false)
    }
  }, [width])

  return (
    <div>
      {!phoneView && !tabView && (
        <>
          <div className={classes.container}>
            <div className={classes.title}>
              <h1>What We Can Do For You</h1>
            </div>
            <div className={classes.subText}>
              <p>{service.PrimaryText}</p>
            </div>
            <div>
              <div className={classes.img}>
                <img src={service.Image} />
              </div>
              <div className={classes.text}>
                <h4>{service.SecondaryText}</h4>
              </div>
            </div>
          </div>
          <div className={classes.expertise}>
            <div className={classes.title}>
              <h1>Our Solutions</h1>
            </div>
            <div className={classes.subText}>
              <p>Which will maximize your customer satisfaction</p>
            </div>
            <Container className={classes.solutions}>
              <Row style={{ textAlign: 'left' }}>
                {service.Solutions.map((solution) => {
                  return (
                    <Col sm>
                      <h5 className={classes.expertiseItems}>{solution}</h5>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
        </>
      )}
      {tabView && (
        <>
          <div className={classes.phoneContainer}>
            <div className={classes.title}>
              <h1>What We Can Do For You</h1>
            </div>
            <div className={classes.subText}>
              <p>{service.PrimaryText}</p>
            </div>
            <div className={classes.tabImg}>
              <img src={service.ImageTab} />
            </div>
            <div className={classes.phoneSecondaryText}>
              <h4>{service.SecondaryText}</h4>
            </div>
            <div className={classes.title}>
              <h1>Our Solutions</h1>
            </div>
            <div className={classes.subText}>
              <p>Which will maximize your customer satisfaction</p>
            </div>
            <Container className={classes.solutions}>
              <Row style={{ textAlign: 'left' }}>
                {service.Solutions.map((solution) => {
                  return (
                    <Col sm>
                      <h5 className={classes.expertiseItems}>{solution}</h5>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
        </>
      )}
      {phoneView && (
        <>
          <div className={classes.phoneContainer}>
            <div className={classes.title}>
              <h1>What We Can Do For You</h1>
            </div>
            <div className={classes.subText}>
              <p>{service.PrimaryText}</p>
            </div>
            <div className={classes.phoneImg}>
              <img src={service.ImagePhone} />
            </div>
            <div className={classes.phoneSecondaryText}>
              <h4>{service.SecondaryText}</h4>
            </div>
            <div className={classes.title}>
              <h1>Our Solutions</h1>
            </div>
            <div className={classes.subText}>
              <p>Which will maximize your customer satisfaction</p>
            </div>
            <Container className={classes.phoneSolutionContainer}>
              <Row>
                {service.Solutions.map((solution) => {
                  return (
                    <Col xs={12} className={classes.phoneSolutions}>
                      <h5>{solution}</h5>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
        </>
      )}
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import useWindowDimensions from '../../useWindowDimensions'
import Map from './map.png'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    padding: '3rem',
  },
  title: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: '1rem',
    '& h1': {
      width: '90%',
      borderBottom: '2px solid #FFCB05',
      fontWeight: 'bold',
      fontFamily: theme.fontFamily.heading,
      fontSize: theme.fontSize.heading,
      color: theme.text.heading,
    },
  },
  img: {
    width: '100%',
    '& img': {
      width: '90%',
    },
  },
  subText: {
    '& p': {
      fontFamily: theme.fontFamily.text,
      fontSize: '18px',
      color: theme.text.subText,
      fontWeight: '500',
    },
  },
}))

const GloballyRenowned = () => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 500) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  })

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <h1>Globally Renowned and Trusted</h1>
      </div>
      <div className={classes.subText}>
        <p>
          We Serve 40+ clients in UK, Canada, US, Western Europe, Middle-East
          Asia, Australia and New Zealand.
        </p>
      </div>
      <div className={classes.img}>
        <img src="https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/Map-webp.webp" />
      </div>
    </div>
  )
}

export default GloballyRenowned

import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Carousel from 'react-bootstrap/Carousel'
import content from '../../ContentJson/content.json'
import { makeStyles } from '@material-ui/styles'
import 'bootstrap/dist/css/bootstrap.css'
import Banner from './bannerImage.png'
import useWindowDimensions from '../../useWindowDimensions'

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    zIndex: '-100',
  },
  carousel: {
    position: 'relative',
    // minHeight: '499px',
    height: '100%',
  },
  carouselItem: {
    minHeight: '500px',
    [theme.breakpoints.up('mobileS')]: {
      minHeight: '600px',
    },
    [theme.breakpoints.up('mobileM')]: {
      minHeight: '700px',
    },
    [theme.breakpoints.up('mobile')]: {
      minHeight: '800px',
    },
    [theme.breakpoints.up('tablet')]: {
      minHeight: '590px',
    },
    [theme.breakpoints.up('smallLaptop')]: {
      minHeight: '400px',
    },
    [theme.breakpoints.up('macbook')]: {
      minHeight: '500px',
    },
    // '& img': {
    //   [theme.breakpoints.down('md')]: {
    //     height: '100vh',
    //   },
    // },
  },
  carouselSlide: {
    position: 'absolute',
    width: '100%',
    top: '0',
    left: '0',
    minHeight: '300px',
    zIndex: -3,
  },
  carouselCaption: {
    position: 'relative',
    // backgroundColor: 'rgba(194, 183, 189, 0.7)',
    zIndex: '3',
    '& h1': {
      fontSize: theme.fontSize.bannerTitle,
      fontFamily: theme.fontFamily.heading,
      textShadow:
        '1px 1px 0px  #000, -1px -1px 0px  #000, 1px -1px 0px  #000, -2px 2px 0px  #000',
    },
    '& p': {
      fontSize: theme.fontSize.p,
      fontFamily: theme.fontFamily.text,
      textShadow:
        '0.5px 0.5px 0px  #000, -0.5px -0.5px 0px  #000, 0.5px -0.5px 0px  #000, -2px 2px 0px  #000',
    },
  },
  contactUs: {
    position: 'relative',
    textDecoration: 'none',
    color: 'black',
    backgroundColor: '#ffc107',
    borderRadius: '20px',
    padding: '0.75rem 6rem',
    [theme.breakpoints.up('mobileS')]: {
      top: '550px',
    },
    [theme.breakpoints.up('mobileM')]: {
      top: '650px',
      padding: '0.5rem 6rem',
    },
    [theme.breakpoints.up('mobile395')]: {
      top: '660px',
    },
    [theme.breakpoints.up('mobile')]: {
      top: '755px',
    },
    [theme.breakpoints.up('tablet')]: {
      top: '500px',
    },
    fontFamily: theme.fontFamily.text,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  contactUsContainer: {
    textAlign: 'center',
  },
}))

export default function ServiceBanner({ service, isHeader }) {
  const classes = useStyles()
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [image, setImage] = useState('')
  const { height, width } = useWindowDimensions()

  useEffect(() => {}, [])

  return (
    <div style={{ display: 'block' }} className={classes.container}>
      <Carousel
        className={classes.carousel}
        controls={false}
        indicators={false}
      >
        <Carousel.Item className={classes.carouselItem} interval={2000}>
          {width < 321 && (
            <>
              <img
                className={classes.carouselSlide}
                src={service.Banner320}
                alt="First slide"
              />
              <div className={classes.contactUsContainer}>
                <a href="/contact-us" className={classes.contactUs}>
                  Contact Us
                </a>
              </div>
            </>
          )}
          {width > 321 && width < 361 && (
            <>
              <img
                className={classes.carouselSlide}
                src={service.Banner360}
                alt="First slide"
              />
              <div className={classes.contactUsContainer}>
                <a href="/contact-us" className={classes.contactUs}>
                  Contact Us
                </a>
              </div>
            </>
          )}
          {width > 361 && width < 376 && (
            <>
              <img
                className={classes.carouselSlide}
                src={service.Banner375}
                alt="First slide"
              />
              <div className={classes.contactUsContainer}>
                <a href="/contact-us" className={classes.contactUs}>
                  Contact Us
                </a>
              </div>
            </>
          )}
          {width > 376 && width < 401 && (
            <>
              <img
                className={classes.carouselSlide}
                src={service.Banner400}
                alt="First slide"
              />
              <div className={classes.contactUsContainer}>
                <a href="/contact-us" className={classes.contactUs}>
                  Contact Us
                </a>
              </div>
            </>
          )}
          {width > 402 && width < 427 && (
            <>
              <img
                className={classes.carouselSlide}
                src={service.Banner420}
                alt="First slide"
              />
              <div className={classes.contactUsContainer}>
                <a href="/contact-us" className={classes.contactUs}>
                  Contact Us
                </a>
              </div>
            </>
          )}
          {width > 427 && width < 770 && (
            <>
              <img
                className={classes.carouselSlide}
                src={service.BannerTab}
                alt="First slide"
              />
              <div className={classes.contactUsContainer}>
                <a href="/contact-us" className={classes.contactUs}>
                  Contact Us
                </a>
              </div>
            </>
          )}
          {width > 770 && width < 1025 && (
            <img
              className={classes.carouselSlide}
              src={service.Banner1024}
              alt="First slide"
            />
          )}
          {width > 1025 && width < 1367 && (
            <img
              className={classes.carouselSlide}
              src={service.Banner1366}
              alt="First slide"
            />
          )}
          {width > 1367 && width < 1441 && (
            <img
              className={classes.carouselSlide}
              src={service.Banner1440}
              alt="First slide"
            />
          )}
          {width > 1441 && (
            <img
              className={classes.carouselSlide}
              src={service.Banner2560}
              alt="First slide"
            />
          )}
          {width > 1023 && (
            <Carousel.Caption>
              <div className={classes.carouselCaption}>
                <h1>{service.Title}</h1>
                <p>{service.SubTitle}</p>
              </div>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import content from '../../ContentJson/content.json'
import { makeStyles } from '@material-ui/styles'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Card from 'react-bootstrap/Card'
import 'bootstrap/dist/css/bootstrap.css'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveIcon from '@material-ui/icons/Remove'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1rem',
  },
  accordianContainer: {
    width: '100%',
  },
  header: {
    width: '100%',
    padding: '1rem',
    backgroundColor: '#EBF3C1',
  },
  text: {
    fontFamily: theme.fontFamily.text,
  },
}))

export default function Faq({ question, icon, eventKey, body }) {
  const classes = useStyles()
  const [iconToggle, setToggle] = useState(icon)

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!'),
    )
    return (
      <div type="button" className={classes.header} onClick={decoratedOnClick}>
        {children}
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Accordion
        defaultActiveKey="0"
        flush
        onClick={() => {
          setToggle(!iconToggle)
        }}
      >
        <Card>
          <Card.Header style={{ padding: '0' }}>
            <CustomToggle eventKey={eventKey}>
              <Row>
                <Col xs={8} className={classes.text}>
                  {question}
                </Col>
                {iconToggle && (
                  <Col xs={4} style={{ textAlign: 'right' }}>
                    <AddCircleIcon />
                  </Col>
                )}
                {!iconToggle && (
                  <Col xs={4} style={{ textAlign: 'right' }}>
                    <RemoveIcon />
                  </Col>
                )}
              </Row>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={eventKey}>
            <Card.Body
              style={{ backgroundColor: '#FDF7E6' }}
              className={classes.text}
            >
              {body}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}

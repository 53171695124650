import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/styles'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useWindowDimensions from '../../useWindowDimensions'
import AdvertHeader from '../Header/AdvertHeader'
import bannerSmallLaptop from './W-1024-D.png'
import bannerLaptop from './W-1440-D.png'
import bannerDesktop from './W-2560-D.png'

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('mobileS')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/W-320-M.png)`,
      height: '768px',
    },
    [theme.breakpoints.up('mobileM')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/W-375-M.png)`,
      height: '768px',
    },
    [theme.breakpoints.up('mobile')]: {
      backgroundImage: `url(https://cogent-images-and-files.s3.ap-south-1.amazonaws.com/W-425-M.png)`,
      height: '768px',
    },
    [theme.breakpoints.up('smallLaptop')]: {
      backgroundImage: `url(${bannerSmallLaptop})`,
      height: '600px',
    },
    [theme.breakpoints.up('macbook')]: {
      backgroundImage: `url(${bannerLaptop})`,
      height: '700px',
    },
    [theme.breakpoints.up('laptop')]: {
      backgroundImage: `url(${bannerLaptop})`,
      height: '800px',
    },
    [theme.breakpoints.up('desktop')]: {
      backgroundImage: `url(${bannerDesktop})`,
      height: '900px',
    },
  },
  subContainer: {
    color: 'white',
    [theme.breakpoints.up('mobileS')]: {
      textAlign: 'center',
      paddingTop: '27rem',
      paddingLeft: '20px',
      paddingRight: '20px',
      '& h4': {
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
        fontSize: '24px',
      },
      '& h1': {
        marginBottom: '1rem',
        fontFamily: theme.fontFamily.heading,
      },
    },
    [theme.breakpoints.up('mobileM')]: {
      textAlign: 'center',
      paddingTop: '27rem',
      paddingLeft: '20px',
      paddingRight: '20px',
      '& h4': {
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
        fontSize: '24px',
      },
      '& h1': {
        marginBottom: '1rem',
        fontFamily: theme.fontFamily.heading,
      },
    },
    [theme.breakpoints.up('mobile')]: {
      textAlign: 'center',
      paddingTop: '27rem',
      paddingLeft: '20px',
      paddingRight: '20px',
      '& h4': {
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
        fontSize: '24px',
      },
      '& h1': {
        marginBottom: '1rem',
        fontFamily: theme.fontFamily.heading,
      },
    },
    [theme.breakpoints.up('smallLaptop')]: {
      textAlign: 'left',
      paddingTop: '13rem',
      paddingLeft: '5rem',
      '& h4': {
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
        fontSize: '24px',
      },
      '& h1': {
        marginBottom: '1rem',
        fontFamily: theme.fontFamily.heading,
      },
    },
    [theme.breakpoints.up('macbook')]: {
      textAlign: 'left',
      backgroundImage: `url(${bannerLaptop})`,
      height: '700px',
    },
    [theme.breakpoints.up('laptop')]: {
      textAlign: 'left',
      paddingTop: '15rem',
      paddingLeft: '7rem',
      '& h4': {
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
        fontSize: '24px',
      },
      '& h1': {
        marginBottom: '1rem',
        fontFamily: theme.fontFamily.heading,
      },
    },
    [theme.breakpoints.up('desktop')]: {
      textAlign: 'left',
      paddingTop: '15rem',
      paddingLeft: '7rem',
      '& h4': {
        marginBottom: '2rem',
        fontFamily: theme.fontFamily.text,
        fontSize: '24px',
      },
      '& h1': {
        marginBottom: '1rem',
        fontFamily: theme.fontFamily.heading,
      },
    },
  },
  button: {
    backgroundColor: theme.background.yellow,
    padding: '15px 15px',
    borderRadius: '20px',
    textDecoration: 'none',
    color: 'white',
  },
  subText: {
    marginTop: '1.5rem',
    '& a': {
      color: theme.text.yellow,
      fontWeight: 'bold',
    },
  },
}))

const ThankYouPage = ({ inView, setInView }) => {
  const classes = useStyles()
  const { height, width } = useWindowDimensions()
  const [phoneView, setPhoneView] = useState(false)

  useEffect(() => {
    if (width < 1025) {
      setPhoneView(true)
    } else {
      setPhoneView(false)
    }
  }, [width])

  return (
    <>
      <div>
        <AdvertHeader />
      </div>
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <h1>
            Thanks For Your <br /> Submission!{' '}
          </h1>
          <h4>
            Your support means the world to us. We'll get <br /> in touch with
            you soon.
          </h4>
          <a href="/" className={classes.button}>
            Go back to Home
          </a>
          <p className={classes.subText}>
            Haven't recieved your mail yet?{' '}
            <a
              href="#"
              onClick={() => {
                window.location = 'mailto:avinash.vasa@cogenthub.com'
              }}
            >
              Let us know
            </a>{' '}
          </p>
        </div>
      </div>
    </>
  )
}

export default ThankYouPage
